.active-modal {
  overflow-y: hidden;
}
.modal {
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: scroll;
  }
  .modal-content {
    z-index: 100000;
    position: relative;
    // top: 40%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    line-height: 1.4;
    padding: 14px 28px;
    border-radius: 5px;
    background-color: rgb(245, 245, 247);
    color: rgb(56, 58, 63);
    // width: 40vw;
    .modal-close-btn {
      float: right;
      font-size: 28px;
      font-weight: 600;
      transition: all 0.3s ease-out 0s;
      cursor: pointer;
      color: rgba(56, 58, 63, 0.4);
      &:hover {
        color: rgba(56, 58, 63, 1);
      }
    }
    .modal-header {
      font-size: 20px;
      padding: 10px 20px;
      font-weight: 500;
    }
    .modal-text {
      padding: 10px 20px;
    }
    form {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      padding: 10px 20px;
      .formSection {
        margin-bottom: 10px;
        
        .formRow {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 25px;
          margin-bottom: 30px;

          .formInput {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 5px;
            .radioInline {
              display: flex;
              gap: 5px;
            }
            label {
              text-transform: uppercase;
              position: relative;
              font-weight: 700;
              display: block;
              font-size: 0.9em;
            }
            .upload-button {
              width: max-content;
              display: inline-flex;
              gap: 5px;
              overflow: hidden;
              -webkit-box-align: center;
              align-items: center;
              position: relative;
              vertical-align: top;
              cursor: pointer;
              height: 38px;
              background-color: #5b3016;
              padding: 0px 15px;
              color: rgb(255, 255, 255);
              border-radius: 3px;
              font-size: 12px;
              transition: all 0.3s ease-out 0s;
              &:hover {
                background-color: #5b3016;
              }
            }
            input[type="file"] {
              display: none;
            }

            input[type="tel"],
            input[type="text"],
            input[type="date"],
            input[type="password"],
            input[type="email"],
            input[type="number"],
            textarea,
            select {
              padding: 10px;
              border: 1px solid rgb(205, 205, 205);
              border-radius: 5px;
              transition: 0.2s ease;
              outline: none;
              &:focus {
                outline: 1px solid blue;
              }
              &:invalid[focused="true"] ~ .error-message {
                display: block;
              }
              &:valid[focused="true"] {
                outline: 1px solid blue;
              }
              &:invalid[focused="true"] {
                outline: 1px solid red;
              }
            }
            small {
              color: rgb(179, 179, 179);
              font-size: 11px;
            }
            .error-message {
              font-size: 13px;
              color: red;
              display: none;
            }
          }
        }
        button{
          display: inline-flex;
            overflow: hidden;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            vertical-align: top;
            cursor: pointer;
            height: 38px;
            padding: 0px 15px;
            color: rgb(255, 255, 255);
            border-radius: 3px;
            font-size: 12px;
            transition: all 0.3s ease-out 0s;
            float: right;
            background: #5b3016;
            border: none;
           
            &:hover{
                cursor: pointer;
                background: #5b3016;
            }
        }
      }
    
    }
  }
}
.modal-content {
  
  line-height: 1.4;
  padding: 14px 28px;
  border-radius: 5px;
  background-color: rgba(245, 245, 247,.5);
  color: rgb(56, 58, 63);
  width: 80%;
  margin: 20px auto;
  .modal-close-btn {
    float: right;
    font-size: 28px;
    font-weight: 600;
    transition: all 0.3s ease-out 0s;
    cursor: pointer;
    color: rgba(56, 58, 63, 0.4);
    &:hover {
      color: rgba(56, 58, 63, 1);
    }
  }
  .modal-header {
    font-size: 20px;
    padding: 10px 20px;
    font-weight: 500;
  }
  .modal-text {
    padding: 10px 20px;
  }
  form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 10px 20px;
    .formSection {
      margin-bottom: 50px;
      
      .formRow {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 25px;
        margin-bottom: 30px;

        .formInput {
          display: flex;
          flex-direction: column;
          flex: 1;
          gap: 5px;
          .radioInline {
            display: flex;
            gap: 5px;
          }
          label {
            text-transform: uppercase;
            position: relative;
            font-weight: 700;
            display: block;
            font-size: 0.9em;
          }
          .upload-button {
            width: max-content;
            display: inline-flex;
            gap: 5px;
            overflow: hidden;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            vertical-align: top;
            cursor: pointer;
            height: 38px;
            background-color: #5b3016;
            padding: 0px 15px;
            color: rgb(255, 255, 255);
            border-radius: 3px;
            font-size: 12px;
            transition: all 0.3s ease-out 0s;
            &:hover {
              background-color: #5b3016;
            }
          }
          input[type="file"] {
            display: none;
          }

          input[type="tel"],
          input[type="text"],
          input[type="date"],
          input[type="password"],
          input[type="email"],
          select {
            padding: 10px;
            border: 1px solid rgb(205, 205, 205);
            border-radius: 5px;
            transition: 0.2s ease;
            outline: none;
            &:focus {
              outline: 1px solid blue;
            }
            &:invalid[focused="true"] ~ .error-message {
              display: block;
            }
            &:valid[focused="true"] {
              outline: 1px solid blue;
            }
            &:invalid[focused="true"] {
              outline: 1px solid red;
            }
          }
          small {
            color: rgb(179, 179, 179);
            font-size: 11px;
          }
          .error-message {
            font-size: 13px;
            color: red;
            display: none;
          }
        }
      }
      button{
        display: inline-flex;
          overflow: hidden;
          -webkit-box-align: center;
          align-items: center;
          position: relative;
          vertical-align: top;
          cursor: pointer;
          height: 38px;
          padding: 0px 15px;
          color: rgb(255, 255, 255);
          border-radius: 3px;
          font-size: 12px;
          transition: all 0.3s ease-out 0s;
          float: right;
          background: #5b3016;
          border: none;
         
          &:hover{
              cursor: pointer;
              background: #5b3016;
          }
      }
    }
  
  }
}


// RESPONSIVE
@media screen and (max-width: 426px){
  .modal {
    
    .modal-content {
      
      
      .modal-close-btn {
        font-size: 20px;
      }
      .modal-header {
        font-size: 13px;
      }
      .modal-row{
        font-size: 13px;
      }
    }
  }
}