.graduate-details{
    
    .graduate-basic-details{
        border-radius: 10px;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.064);
        .graduate-details-top{
            padding: 15px;
            display: flex;
            justify-content: space-between;
            .graduate-basic-details-left{
                display: flex;
                gap:10px;
                align-items: center;
                .graduate-profile-picture{
                    padding: 20px;
                    img{
                        background-color: rgb(245, 245, 247);
                        border-radius: 100%;
                        height: 7rem;
                        width: 7rem;
                        object-fit: cover;
                        transition: 1s ease;
                        &:hover{
                            box-shadow: 0 0 1rem rgba(0,0,0,.6);
                            background-color: rgba(255,255,255,.25);
                        }
                    }
                }
                .graduate-personal-info{
                    padding: 20px;
                    
                    .graduate-name{
                        font-size: 20px;
                        display: block;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                    .graduate-school-id{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        color: #383A3F;
                        font-weight: 400;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .graduate-details-tabs{
            display: flex;
            padding: 0px 15px;
            .graduate-details-tab{
                padding: 15px 15px;
                cursor: pointer;
            }
            .graduate-details-tab-active{
                border-bottom: 4px solid rgb(0, 114, 187);;
                color: rgb(0, 114, 187);
            }
        }
    }
}