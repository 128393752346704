@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* ------------------------------- *\
        # RESET
\* -------------------------------- */
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  list-style-type: none;
}

/* ------------------------------- *\
        # VARIABLES
\* -------------------------------- */
:root {
  --primary-clr: #5b3016;
  --primary-clr-2: #009faa;
  --secondary-clr: #844620;
  --title-clr: #334257;
  --dark-clr: #1a5488;
  --base-clr: #2aa26a;
  --base-clr-2: #ffdfcc78;
  --border-clr: #99a7ba45;
  --warning-clr: #ff7500;
  --danger-clr: #ff6d6d;
  --success-clr: #00aa6d;
  --info-clr: #0096ff;
  --theameColor: #045cff;
  --title-2: #758590;
  --title-3: #107980;
  --hover-clr: #1b7fed;
  --white: #fff;
}

#subscription{
  background-color:"#5BB1F2";
  display:"flex";
  align-items:"center";
  justify-content:"center";
  color:"white";
}

.no-data{
  /* height: 50vh; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  padding: 50px 0px;
}

.no-data img{
  height: 300px;
}

.custom-drop-shadow{
  box-shadow: rgba(47, 5, 187, 0.2) 1px 3px 6px;
}

.header-background{
  background-image: radial-gradient(circle at 85% 40%, rgba(204, 204, 204,0.08) 0%, rgba(204, 204, 204,0.08) 33.333%,rgba(188, 188, 188,0.08) 33.333%, rgba(188, 188, 188,0.08) 66.666%,rgba(171, 171, 171,0.08) 66.666%, rgba(171, 171, 171,0.08) 99.999%),radial-gradient(circle at 27% 80%, rgba(196, 196, 196,0.08) 0%, rgba(196, 196, 196,0.08) 33.333%,rgba(115, 115, 115,0.08) 33.333%, rgba(115, 115, 115,0.08) 66.666%,rgba(33, 33, 33,0.08) 66.666%, rgba(33, 33, 33,0.08) 99.999%),radial-gradient(circle at 95% 54%, rgba(223, 223, 223,0.08) 0%, rgba(223, 223, 223,0.08) 33.333%,rgba(168, 168, 168,0.08) 33.333%, rgba(168, 168, 168,0.08) 66.666%,rgba(112, 112, 112,0.08) 66.666%, rgba(112, 112, 112,0.08) 99.999%),linear-gradient(45deg, rgb(31, 80, 162),rgb(43, 81, 194));
}