.card{
    height: 200px;
    flex: 1 0 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    text-align: center;
    margin-top: 20px;
    transition: 0.5s ease;
    &:hover{
        cursor: pointer;
        scale: 1.1;
    }
    .users-img{
        height: 120px;
    }
    h5{
        color: var(--main-color);
        font-size: 16px;
    }
    p{
        font-size: 14px;
        width: 70%;
        color: var(--secondaryColor);
    }
}

