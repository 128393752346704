#footer{
    height: max-content;
    padding: 50px 100px;
    // background-color: #F1F8FC;
    background-color: #1a1e39;
    background-image: url("../../assets/footer-bg.png");
    background-size: cover;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 100px;
    .about{
        flex: 1;
        .logo{
            img{
                height: 40px;
                
            }
        }
        p{
            line-height: 1.3;
            // font-weight: 200;
            font-size: 13px;
            text-align: justify;
        }
    }
    .contact{
        flex: 1;
        height: max-content;
        display: flex;
        flex-direction: column;
        gap: 16px;

        h3{
            font-size: 24px;
        }
        ul{
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-weight: 300;
            letter-spacing: .3px;
            font-size: 15px;
        }

    }
    .social-media{
        flex: 1;
        h3{
            font-size: 24px;
        }
        display: flex;
        flex-direction: column;
        gap: 25px;
        .icons{
            display: flex;
            font-size: 25px;
            gap: 12px;
            .icon{
                transition: .5s ease;
                color:rgb(147, 147, 255);
                font-size: 25px;
                &:hover{
                    color: rgb(84, 84, 255);
                    cursor: pointer;
                }
            }
        }
      
    }
}

@media screen and (max-width: 1025px) {
    #footer{
        .about{
            .logo{
                img{
                    height: 30px;
                }
            }
            p{
                font-size: 10px;
            }
        }
        .contact{
            h3{
                font-size: 20px;
            }
            ul{
                font-size: 10px;
            }
        }
        .social-media{
            h3{
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    #footer{
        padding: 50px 30px;
        gap: 30px;
    }
}

@media screen and (max-width: 426px) {
    #footer{
        flex-direction: column;
        
    }
}