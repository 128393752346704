#join-us{
    padding: 60px 100px ;
    background-color: #373BD7;
    color: white;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .left-joinus{
        flex: 1 0 50%;
        
        h3{
            margin: 20px 0;
            font-size: 28px;
            width: 75%;
            font-weight: 900;
            line-height: 1.1;
        }
        .bottom{
            width: 50%;
            margin-bottom: 20px;
        }
        .button{
            background-color: #3fb7b6;
            padding: 10px 20px;
            width: max-content;
        }
    }
    .right-joinus{
        flex: 1 0 50%;
        .person{
            width: 103%;
        }
    }
}


@media screen and (max-width:426px) {
    #join-us{
        padding: 52px 25px;
        
        .left-joinus{
            padding: 0;
            .top{
                font-size: 12px;
            }
            h3{
                margin: 20px 0;
                font-size: 23px;
                width: 100%;
            }
            .bottom{
                width: 100%;
            }
        }
        .right-joinus{
            display: none;
        }
    }
}

@media screen and (max-width:769px) {
    #join-us{
        
        
        .left-joinus{
            padding: 0;
            .top{
                font-size: 14px;
            }
            h3{
                margin: 20px 0;
                font-size: 28px;
                width: 100%;
            }
            .bottom{
                width: 100%;
            }
        }
       
    }
}


@media screen and (max-width:1024px) {
    #join-us{
        
        
        .left-joinus{

            h3{

                width: 100%;
            }
            .bottom{
                width: 100%;
            }
        }
       
    }
}