#users{
    padding: 53px 100px 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    
    .title{
        font-weight: 400;
        padding: 20px;
        
        border-bottom: 1px solid rgb(231, 231, 231);
    }
    .users-list{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;
        height: 70%;
        align-items: center;
        justify-content: space-around;

    }
}
@media screen and (max-width: 1024px){
    #users{
        
    }
}

@media screen and (max-width: 768px){
    #users{
        padding: 10px 25px 40px;
        
        .title{
            font-size: 15px;
            text-align: center;
        }
    }
}
@media screen and (max-width: 426px){
    #users{
        padding: 10px 25px 30px;
        
        .title{
            font-size: 14px;
            text-align: center;
        }
    }
}
@media screen and (max-width: 376px){
    #users{
        padding: 10px 25px 30px;
        height: max-content;
        .title{
            font-size: 13xpx;
            text-align: center;
        }
    }
}
