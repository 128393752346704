#pricing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 50px 0px 80px;
    background-color: #d6edfc;
    h3{
        font-size: 40px;
        text-align: center;
        padding: 0px 20px;
    }
    p{
        color: gray;
        padding: 0px 20px;
        text-align: center;
    }
    .packages{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 30px;
        width: 100%;
        margin-top: 40px;
        .price{
            -webkit-box-shadow: -16px -6px 128px -111px rgba(0,0,0,0.23);
            -moz-box-shadow: -16px -6px 128px -111px rgba(0,0,0,0.23);
            box-shadow: -16px -6px 128px -111px rgba(0,0,0,0.23);
            border: 1px solid rgb(215, 215, 215);
            border-radius: 10px;
            padding: 50px 30px;
            display: flex;
            flex-direction: column;
            gap: 27px;
            background-color: white;
            width: 190px;
            .title{

                h4{
                    font-size: 20px;
                    font-weight: 500;
                }
                p{
                    font-size: 11px;
                }
            }
            .package-price{
                display: flex;
                flex-direction: column;
                align-items: center;
                .amount{
                    font-size: 35px;
                    font-weight: bold;
                    color: black;
                    letter-spacing: -4px;
                }
                .price-text{
                    font-size: 13px;
                }
            }
            .button{
                background: #435EF9;
                padding: 10px;
                color: white;
                font-weight: 500;
                border-radius: 5px;
                text-align: center;
                font-size: 12px;
            }
            .list{
                h5{
                    margin-bottom: 10px;
                    font-weight: 500;
                    font-size: 11px;
                }
                .list-item{
                    display: flex;
                    gap: 10px;
                    .icon{
                        color: #3EA7F4;
                    }
                    .text{
                        font-size: 13px;
                        color: #6e6e6e;
                    }
                    .popular{
                        color: white;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 426px) {
    #pricing{
        h3{
            font-size: 30px;
        }
    }
}