.side-menu-item{
    transition: .4s ease;
    &:hover{
        background-color: #844620;
    }
    .side-menu-link{
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: .4s ease-in;
        .icon-name{
            display: flex;
            align-items: center;
            gap: 10px;
            font-size:18px;
            padding: 5px 0;
            
            .icon{
                transition: .3s ease-in-out;
                font-size: 15px;
            }
            .name{
                font-weight: 500;
                transition: .3s ease-in-out;
                font-size: 13px;
            }
            &:hover{
                color: #fff;
            }
        }
        
    }
    .submenu{
        flex-direction: column;
        gap: 10px;
        justify-content: flex-end;
        align-items: flex-end;
        transition: .4s ease-in;
        

        .submenu-item{
            padding: 10px 10px 10px 20px;
            width: 100%;
            text-align: right;
            transition: .4s ease;
            font-size: 13px;
            
            
            a{
                font-weight: 500;
                transition: .4s ease-in;
                padding: 10px 0px 20px;
            }
            &:hover{
                
                background-color: #844620;
            }
        }
    }
}