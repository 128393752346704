.main-container{
    min-height: 100vh;
    flex-wrap:wrap;
    transition: all 0.5s;
    
    .main-page{
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px 0px;    
        overflow: hidden;
        
    }
}