main {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  // background: #ff8c6b;
  // background: linear-gradient(360deg,#022687 0%, #0571E1 );
  background-image: radial-gradient(
      circle at 33% 41%,
      rgba(250, 250, 250, 0.03) 0%,
      rgba(250, 250, 250, 0.03) 50%,
      rgba(37, 37, 37, 0.03) 50%,
      rgba(37, 37, 37, 0.03) 100%
    ),
    radial-gradient(
      circle at 76% 49%,
      rgba(28, 28, 28, 0.03) 0%,
      rgba(28, 28, 28, 0.03) 50%,
      rgba(187, 187, 187, 0.03) 50%,
      rgba(187, 187, 187, 0.03) 100%
    ),
    radial-gradient(
      circle at 41% 99%,
      rgba(247, 247, 247, 0.03) 0%,
      rgba(247, 247, 247, 0.03) 50%,
      rgba(120, 120, 120, 0.03) 50%,
      rgba(120, 120, 120, 0.03) 100%
    ),
    radial-gradient(
      circle at 66% 27%,
      rgba(17, 17, 17, 0.03) 0%,
      rgba(17, 17, 17, 0.03) 50%,
      rgba(156, 156, 156, 0.03) 50%,
      rgba(156, 156, 156, 0.03) 100%
    ),
    linear-gradient(0deg, #844620, #5b3016);
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    position: relative;
    width: 100%;
    max-width: 1020px;
    height: 640px;
    background-color: #fff;
    border-radius: 0.3rem;
    box-shadow: 0 60px 40px -30px rgba(0, 0, 0, 0.27);
    .inner-box {
      position: absolute;
      width: calc(100% - 4.1rem);
      height: calc(100% - 4.1rem);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .forms-wrap {
        position: absolute;
        height: 100%;
        width: 45%;
        top: 0;
        left: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        transition: 0.8s ease-in-out;
        form {
          max-width: 260px;
          width: 100%;
          margin: 0 auto;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 50px;
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          transition: opacity 0.02s 0.4s;
          .logo {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            img {
              height: 40px;
              margin-right: 0.3rem;
            }
          }
          .heading {
            h3 {
              font-size: 2.5rem;
              font-weight: 800;
              color: #151111;
            }
            h6 {
              color: #303030;
              font-weight: 400;
              font-size: 1rem;
              display: inline;
            }
          }
          .form-body {
            .actual-form {
              .input-wrap {
                position: relative;
                height: 37px;
                margin-bottom: 2rem;
                .input-field {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background: none;
                  border: none;
                  outline: none;
                  border-bottom: 1px solid #bbb;
                  padding: 0;
                  font-size: 0.95rem;
                  color: #141010;
                  transition: 0.4s;
                  &:focus {
                    border-bottom-color: #151111;
                  }
                  &:focus + label,
                  &:valid + label {
                    font-size: 0.75rem;
                    top: -2px;
                  }
                }
                label {
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  font-size: 0.95rem;
                  color: #bbb;
                  pointer-events: none;
                  transition: 0.4s;
                }
              }
              .sign-btn {
                display: inline-block;
                width: 100%;
                height: 43px;
                background-color: #5b3016;
                color: #fff;
                border: none;
                cursor: pointer;
                border-radius: 0.2rem;
                margin-bottom: 2rem;
                transition: 0.3s;
                &:hover {
                  background-color: #ad5b2a;
                  outline: none;
                }
              }
              .loading-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 43px;
                background-color: #545454;
                color: #fff;
                border: none;
                border-radius: 0.2rem;
                cursor: default;
                margin-bottom: 2rem;
                transition: 0.3s;
              }
              .text {
                color: rgb(107, 107, 107);
                font-size: 0.8rem;
                a {
                  color: rgb(107, 107, 107);
                  transition: 0.3s;
                  &:hover {
                    color: #2000f0;
                  }
                }
              }
            }
          }
        }
      }
      .carousel {
        position: absolute;
        height: 100%;
        width: 55%;
        left: 45%;
        top: 0;
        // background-color: #ffe0d2;
        border-radius: 2rem;
        display: grid;
        grid-template-rows: auto 1fr;
        padding-bottom: 2rem;
        overflow: hidden;
        transition: 0.8s ease-in-out;
        .images-wrapper {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
          .image {
            width: 100%;
            grid-column: 1/2;
            grid-row: 1/2;
            opacity: 0;
            transition: opacity 0.3s, transform 0.5s;
          }
          .img-1 {
            transform: translate(0, -50px);
          }

          .img-2 {
            transform: scale(0.4, 0.5);
          }

          .img-3 {
            transform: scale(0.3) rotate(-20deg);
          }
          .show {
            opacity: 1;
            transform: none;
          }
        }
        .text-slider {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .text-wrap {
            max-height: 2.2rem;
            overflow: hidden;
            margin-bottom: 2.5rem;
            .text-group {
              display: flex;
              flex-direction: column;
              text-align: center;
              transform: translateY(0);
              transition: 0.5s;
              h2 {
                line-height: 2.2rem;
                font-weight: 600;
                font-size: 1.6rem;
              }
            }
          }
          .bullets {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              display: block;
              width: 0.5rem;
              height: 0.5rem;
              background-color: #aaa;
              margin: 0 0.25rem;
              border-radius: 50%;
              cursor: pointer;
              transition: 0.3s;
            }
            span.active {
              width: 1.1rem;
              background-color: #151111;
              border-radius: 1rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  main {
    .box {
      .inner-box {
        .forms-wrap {
          position: initial;
          width: 100%;
        }
        .carousel {
          display: none;
        }
      }
    }
  }
}
