.mailbox-body{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .mail-left{
        flex: 1;
        border: 1px solid #D8D8D8;
        border-radius: 5px;
        padding: 15px;
        height: max-content;
        .compose{
            background-color: #C2E7FF;
            padding: 10px;
            color: black;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 20px;
            margin-top: 15px;
            display: flex;
            gap: 15px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .mailsections{
            margin-top: 15px;

            .mail-active{
                background-color: #D3E3FD;
            }
            .section{
                margin-top: 5px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 5px;
                padding: 10px;
                transform: .5s ease ;
                .text{
                    display: flex;
                    align-items: center;
                    gap: 5px;    
                }
                .number{
                    font-weight: 400;
                    color: white;
                    font-size: 14px;
                    background-color: #FF6D60;
                    padding: 2px 5px;
                    border-radius: 30%;
                }
                &:hover{
                    background-color: rgb(240, 240, 240);
                }
                
            }
        }
    }
    .mail-right{
        flex: 4;
        border: 1px solid #D8D8D8;
        border-radius: 5px;
        padding: 15px;
        .mail-right-top{
            font-size: 20px;
            display: flex;
            gap: 10px;
            margin-bottom: 20px;
            .backButton{
                font-size: 30px;
                padding: 5px;
                border-radius: 50%;
                transition: .5s ease;
                &:hover{
                    background-color: #D8D8D8;
                    cursor: pointer;
                }
            }
        }
        .mails{
            .formInput{
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                label{
                    font-weight: 600;
                    color: #1fa8a1;
                }
                textarea,input[type=text],input[type=date],input[type=password],input[type=email], select{
                    padding: 10px;
                    border: 1px solid rgb(205, 205, 205);
                    border-radius: 5px;
                    transition: .2s ease;
                    outline: none;
                    &:focus{
                        outline: 1px solid blue;
                    }
                    &:invalid[focused = "true"] ~ .error-message{
                        display: block;
                    }
                    &:valid[focused="true"]{
                        outline: 1px solid blue;
                    }
                    &:invalid[focused="true"]{
                        outline: 1px solid red;
                    }
                }
                
            }
            .mail-icon{
                margin-right: 4px;
                padding: 3px;
                font-size: 17px;
                &:hover{
                    background-color: #D8D8D8;
                    
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
            .sendBtn{
                margin-top: 15px;
                padding:10px 20px;
                background: #1fa8a1;
                border: none;
                color: white;
                font-size: 20px;
                transition: .4s ease;
                &:hover{
                    cursor: pointer;
                    background: #1c9c96;
                }
            }
            .mail{
                .subject{
                    font-size: 2rem;
                    font-weight: 400;
                    text-transform: capitalize;
                    padding: 10px 20px;
                    color: #1f1f1f;
                }
                .sender{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    padding: 20px;
                    .message-details{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                }
                .message-body{
                    line-height: 1.6;
                    font-size: 20px;
                    width: 70%;
                    margin: auto;
                    text-align: justify;
                }
            }
        }
    }
}

@media screen and (max-width: 426px){
    .mailbox-body{
        .mail-right{
            flex: auto;
            overflow: scroll;
        }
    }
}