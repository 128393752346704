#hero{
    scroll-behavior: smooth;

    padding: 10px 100px;
    overflow: hidden;
    background-color: var(--backgroundColor);
    
    .hero-section{
        padding-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .left-hero{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 30px 30px 30px 0;
            width: 50%;
            
            h2{
                font-size: 3rem;
                color:rgb(45, 45, 45);
                font-weight: 900;
                letter-spacing: -1px;
            }
            p{
                margin-top: 10px;
                line-height: 1.4;
                width: 80%;
                text-align: justify;
                color: rgb(77, 77, 77);
            }
           
        }
        .right-hero{
            flex: 1;
            .person{
                width: 73%;
                transform: translate(30%, 20px);
            }
        }
    }
}


@media screen and (max-width: 1024px) {
    #hero{
        
        
        .hero-section{
            
            .left-hero{
                flex: 1;
                padding: 0px;
                h2{
                    font-size: 2rem;
                    color:rgb(45, 45, 45)
                }
                p{
                    margin-top: 10px;
                    line-height: 1.2    ;
                    width: 80%;
                    text-align: justify;
                    color: rgb(77, 77, 77);
                    font-size: 13px;
                }
            }
            .right-hero{
                flex: 1;
            }
        }
    }
    
}


@media screen and (max-width: 885px) {
    #hero{
        padding: 10px 40px;
        height: max-content;
        .hero-section{
            
            .left-hero{
                flex: 1;
                padding: 10px;
                
                h2{
                    font-size: 1.8rem;
                    color:rgb(45, 45, 45)
                }
                p{
                    margin-top: 8px;
                    line-height: 1.3;
                    width: 80%;
                    text-align: justify;
                    color: rgb(77, 77, 77);
                    font-size: 13px;
                }
            }
            .right-hero{
                flex: 1;
            }
        }
    }
    
}

@media screen and (max-width: 428px) {
    #hero{
        padding:10px 20px;
        
        .hero-section{
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .left-hero{
                flex: 1;
                width: 90%;
                
                h2{
                    font-size: 1.7rem;
                    color:rgb(45, 45, 45)
                }
                p{
                    margin-top: 10px;
                    line-height: 1.3;
                    width: 100%;
                    text-align: justify;
                    color: rgb(77, 77, 77);
                }
            }
            .right-hero{
                flex: 1;
                .person{
                    width: 80%;
                    transform: translate(10%, 0px);
                }
            }
        }
    }
    
}

@media screen and (max-width: 321px) {
    #hero{
        padding:10px 20px;
        height: 90vh;   
    }
    
}