.exam-schedule{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    
    margin:auto;
    .exam-schedule-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .exam-schedule-header{
            font-size: 24px;
            width: max-content;
            font-weight: 600;
            border-bottom: 1px solid #025464;
            color: #025464;
        }
        form{
            padding-bottom: 40px;
            .formSection{
                margin-bottom: 50px;
                .sectionHeader{
                    border-top: 1px solid #1B9C85;
                    padding-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    p{
                        font-weight: 700;
                    }
                }
                .formRowHeader{
                    margin-bottom: 10px;
                    font-size: 20px;
                    font-weight: 700;
                }
                .formRow{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 25px;
                    margin-bottom: 30px;
                    
                    .formInput{
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        gap: 5px;
                        .radioInline{
                            display: flex;
                            gap: 5px;
                        }
                        label{
                            font-weight: 600;
                            color: #1fa8a1;
                        }
                        input[type=text],input[type=time],input[type=date],input[type=password],input[type=email], select{
                            padding: 10px;
                            border: 1px solid rgb(205, 205, 205);
                            border-radius: 5px;
                            transition: .2s ease;
                            outline: none;
                            &:focus{
                                outline: 1px solid blue;
                            }
                            &:invalid[focused = "true"] ~ .error-message{
                                display: block;
                            }
                            &:valid[focused="true"]{
                                outline: 1px solid blue;
                            }
                            &:invalid[focused="true"]{
                                outline: 1px solid red;
                            }
                        }
                        small{
                            color:rgb(179, 179, 179);
                            font-size: 11px;
                        }
                        .error-message{
                            font-size: 13px;
                            color: red;
                            display: none;
                        }
                    }
                }
            }
            .schedule-buttons{
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                .addBtn{
                  padding: 10px 20px;
                  background: #1f56a8;
                  border: none;
                  color: white;
                  font-size: 15px;
                  transition: 0.4s ease;
                  &:hover {
                    cursor: pointer;
                    background: #2068ac;
                  }
                }
            }
            button {
                width: max-content;
                display: inline-flex;
                gap: 5px;
                overflow: hidden;
                -webkit-box-align: center;
                align-items: center;
                position: relative;
                vertical-align: top;
                cursor: pointer;
                height: 38px;
                background-color: #5b3016;
                padding: 0px 15px;
                color: rgb(255, 255, 255);
                border-radius: 3px;
                font-size: 12px;
                transition: all 0.3s ease-out 0s;
                border: none;
                &:hover{
                    background-color:#5b3016;
                }
            }
            .loading-btn{
                display: flex;
                gap: 10px;
                align-items: center;
                width: max-content;
                padding: 10px 20px;
                background: #d8d8d8;
                border: none;
                color: white;
                font-size: 20px;
                &:hover{
                    cursor: default;
                }
            }
        }
        
    }
}

// RESPONSIVENESS
@media screen and (max-width: 426px) {
    .exam-schedule{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        width: auto;
        margin:auto;
        .exam-schedule-content{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .exam-schedule-header{
                font-size: 24px;
                width: max-content;
                font-weight: 600;
                border-bottom: 1px solid #025464;
                color: #025464;
            }
            form{
                padding-bottom: 40px;
                .formSection{
                    margin-bottom: 50px;
                    .sectionHeader{
                        border-top: 1px solid #1B9C85;
                        padding-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        p{
                            font-weight: 700;
                        }
                    }
                    .formRowHeader{
                        margin-bottom: 10px;
                        font-size: 20px;
                        font-weight: 700;
                    }
                    .formRow{
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        gap: 25px;
                        margin-bottom: 30px;
                        
                        .formInput{
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            gap: 5px;
                            .radioInline{
                                display: flex;
                                gap: 5px;
                            }
                            label{
                                font-size: 13px;
                            }
                            input[type=text],input[type=time],input[type=date],input[type=password],input[type=email], select{
                                padding: 7px;
                            }
                            
                        }
                    }
                }
                .schedule-buttons{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                    .addBtn{
                      padding: 10px;
                      background: #1f56a8;
                      border: none;
                      color: white;
                      font-size: 10px;
                      transition: 0.4s ease;
                      &:hover {
                        cursor: pointer;
                        background: #2068ac;
                      }
                    }
                }
                button {
                    width: max-content;
                    display: inline-flex;
                    gap: 5px;
                    overflow: hidden;
                    -webkit-box-align: center;
                    align-items: center;
                    position: relative;
                    vertical-align: top;
                    cursor: pointer;
                    height: 38px;
                    background-color: #5b3016;
                    padding: 0px 15px;
                    color: rgb(255, 255, 255);
                    border-radius: 3px;
                    font-size: 12px;
                    transition: all 0.3s ease-out 0s;
                    border: none;
                    &:hover{
                        background-color:#5b3016;
                    }
                }
                .loading-btn{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    width: max-content;
                    padding: 10px 20px;
                    background: #d8d8d8;
                    border: none;
                    color: white;
                    font-size: 20px;
                    &:hover{
                        cursor: default;
                    }
                }
            }
            
        }
    }
}