.get-started{
    background-color: #0e7dd1;
    text-transform: capitalize;
    padding: 10px;
    border: none;
    border-radius: 10px;
    color: white;
    transition: 0.5s ease-in-out;
    width: max-content;
    &:hover{
        background-color: #5bacdf;
        cursor: pointer;
    }
}


@media screen and (max-width: 1024px) {

    .get-started{
        font-size: 12px;
    }
    
}

@media screen and (max-width: 768px) {

    .get-started{
        font-size: 9px;
    }
    
}

@media screen and (max-width: 540px) {

    .get-started{
        display: none;
    }
    
}