a{
    color: inherit;
}
.active-link{
    color: red;
    background-color: blue;
}
#navbar{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    
    .logo{
        
        .logo-img{
            height: 46px
        }
    }
    .navs{
        margin-right: 100px;
        .navigation{
            display: flex;
            list-style: none;
            gap: 40px;
            .active{
                color: #0561EE;
                padding-bottom: 3px;
                border-bottom: 2px solid #0561EE;
            }
            .nav-item{
                position: relative;
                display: inline-block;
                text-decoration: none;
                font-size: 20px;
                &::before, &::after, .active{
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #0561EE;
                    transform: scaleX(0);
                    transition: transform 0.25s;
                }
                &::before{
                    top: -3px;
                    transform-origin: left;
                    
                }
                &::after{
                    bottom: -3px;
                    transform-origin: right;
                }
                &:hover::before, &:hover::after{
                    transform: scaleX(1);
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }

   
    .navbar-menu-container{
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    #navbar{
        .logo{
            .logo-img{
                height: 25px;
            }
        }
        .navs{
            .navigation{
                font-size: 12px;
                gap: 15px;
                
            }
        }
        .get-started{
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 768px) {
    #navbar{
        .logo{
            .logo-img{
                height: 20px;
            }
        }
        .navs{
            .navigation{
                font-size: 10px;
            }
        }
        .get-started{
            font-size: 9px;
        }
    }
}

@media screen and (max-width: 540px) {
    #navbar{
        .logo{
            .logo-img{
                height: 25px;
            }
        }
        .navs{
            .navigation{
                display: none;
            }
        }
        
        .navbar-menu-container{
            display: block
        }
    }
}