.subscriptions{
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(216, 216, 227);
  .subscriptions-header{
    margin: 0px;
    padding: 30px;
    h3{
      font-size: 20px;
      font-weight: 600;
    }
    p{
      font-size: 12.8px;
      color: rgb(157, 157, 183);
    }
  }
  .subscriptions-plan{
    margin: 0px;
    padding: 20px 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    .plan{
      border-radius: 10px;
      border: 1px solid rgb(216, 216, 227);
      padding: 20px 20px 15px;
      flex: 1;
      .plan-details{
        .plan-name{
          padding: 10px 20px 15px 0px;
        }
        .plan-status{
          border: none;
          background: rgb(188, 213, 255);
          border-radius: 3px;
          display: inline-flex;
          overflow: hidden;
          -webkit-box-align: center;
          align-items: center;
          height: 30px;
          padding: 0px 15px;
          font-size: 12px;
          transition: all 0.3s ease-out 0s;
          border: none;
          color: #0060b5;
        }
      }
      .plan-pricing{
        margin: 0px;
        padding: 20px 20px 15px 0px;
        display: flex;
        align-items: baseline;
        h3{
          font-size: 30px;
          font-weight: 700;
        }
        span{
          margin-left: 5px;
          font-size: 12.8px;
        }
      }
    }
    
  }
  .billing-history{
    padding: 30px;
    .billing-history-header{
      padding-bottom: 30px;
      h3{
        font-weight: 600;
        font-size: 16px;
      }
      p{
        font-size: 12.8px;
        color: rgb(157, 157, 183);
      }
    }
    .billing-history-list{
      padding-bottom: 15px;
      margin: 20px 0px;
      font-size: 12px;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(208, 213, 217);
      border-radius: 5px;
      table{
        background-color: rgb(255, 255, 255);
        border-collapse: collapse;
        border-radius: 5px;
        width: 100% !important;
        thead{
          color: rgb(56, 58, 63);
          border-bottom: none;
          th{
            text-align: left;
            font-weight: bold;
            padding: 20px;
          }
        }
        tbody{
          tr{
            transition: all 0.3s ease-out 0s;
            border-bottom: none;
            &:nth-child(2n+1) {
              background-color: rgba(208, 213, 217, 0.2);
            }
            &:hover{
              background-color: rgba(162, 212, 255,.3);
            }
            td{
              padding: 20px;
              text-align: left;
              .invoice-download-button{
                display: inline-flex;
                overflow: hidden;
                -webkit-box-align: center;
                align-items: center;
                position: relative;
                vertical-align: top;
                cursor: pointer;
                height: 38px;
                padding: 0px 15px;
                border-radius: 3px;
                font-size: 12px;
                transition: all 0.3s ease-out 0s;
                border: 1px solid rgb(56, 58, 63);
                color: rgb(56, 58, 63);
                z-index: 0;
                background: rgba(56, 58, 63, 0);
                &:hover{
                  background-color: rgba(56, 58, 63, .05);
                }
                
              }
              
            }
          }
        } 
          
      }
    }
  }
}