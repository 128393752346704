.main-container {
  padding: 1px 30px;
  width: -webkit-fill-available;
  .header {
    margin-bottom: 25px;
    h1 {
      font-size: 40px;
      color: #111111;
      font-weight: 800;
    }
  }
  .academic-year-details {
    display: flex;
    gap: 30px;
  }
  .terms {
    margin-top: 30px;
    .terms-list {
      display: block;
      margin: 20px 0px;
      font-size: 12px;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(208, 213, 217);
      border-radius: 5px;
      table {
        background-color: rgb(255, 255, 255);
        border-collapse: collapse;
        border-radius: 5px;
        width: 100% !important;
        thead {
          border-bottom: 1px solid #f5f5f5;
          th {
            text-align: left;
            font-weight: bold;
            padding: 13px 20px;
          }
        }
        tbody {
          tr {
            transition: all 0.3s ease-out 0s;
            border-bottom: 1px solid #f5f5f5;
            td {
              padding: 15px 20px;
              text-align: left;
              font-size: 14px;
              .update-button,
              .set-current-term-btn {
                display: inline-flex;
                overflow: hidden;
                -webkit-box-align: center;
                align-items: center;
                position: relative;
                vertical-align: top;
                cursor: pointer;
                height: 35px;
                background-color: rgb(0, 114, 187);
                padding: 0px 15px;
                color: rgb(255, 255, 255);
                border-radius: 3px;
                font-size: 12px;
                transition: all 0.3s ease-out 0s;
                margin-right: 10px;
                &:hover {
                  background-color: #1d5b79;
                }
              }
              .indicator {
                height: 0.4125rem;
                width: 0.4125rem;
                margin: 0.275rem;
                border-radius: 0.4125rem;
                background-color: rgb(44, 218, 157);
              }
            }
          }
        }
      }
    }
  }
  .disclaimer {
    
    h4 {
      font-size: 25px;
    }
    ul {
      margin-top: 20px;
      list-style-type: decimal;
      margin-left: 20px;
      li {
        list-style-type: decimal;
        padding: 10px 0;
        a {
          text-decoration: underline;
          color: #6f9aff;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid rgb(224, 224, 224);
    padding: 20px 10px;
    .left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 140px;
        width: 140px;
        
        object-fit: cover;
      }
    }
    .right {
      flex: 3;
      form {
        padding-bottom: 40px;
        .formSection {
          margin-bottom: 50px;
          .formRowHeader {
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 700;
          }
          .formRow {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 25px;
            margin-bottom: 30px;
            .children-list{
              h4{
                font-weight: 600;
                text-decoration: underline;
                margin-bottom: 5px;
              }
              ul li{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                margin-bottom: 5px;
                svg{
                  background-color: rgb(224, 36, 36);
                  padding: 5px;
                  border-radius: 5px;
                  color: white;
                  cursor: pointer;
                  border: 1px solid rgb(224, 36, 36);
                  transition: .5s ease-in-out;
                  &:hover{
                    background-color: #fff;
                    color: rgb(244,36,36);
                  }
                }
              }
            }
            .PhoneInputInput {
              padding: 10px;
              border: 1px solid rgb(205, 205, 205);
              border-radius: 5px;
              transition: 0.2s ease;
              outline: none;
              &:focus {
                outline: 1px solid blue;
              }
            }
            .generate-password {
              border: none;
              background-color: #1c9c96;
              color: white;
              padding: 15px;
              height: max-content;
              width: max-content;
              margin-top: 20px;
              cursor: pointer;
            }

            .formInput {
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 5px;
              .radioInline {
                display: flex;
                gap: 5px;
              }
              label {
                font-weight: 600;
              }
              #upload-button {
                width: max-content;
                display: inline-flex;
                overflow: hidden;
                -webkit-box-align: center;
                align-items: center;
                position: relative;
                vertical-align: top;
                cursor: pointer;
                height: 38px;
                background-color: #5b3016;
                padding: 0px 15px;
                color: rgb(255, 255, 255);
                border-radius: 3px;
                font-size: 12px;
                transition: all 0.3s ease-out 0s;
                &:hover {
                  background-color: #5b3016;
                }
              }
              input[type="file"] {
                display: none;
              }
              input[type="text"],
              input[type="date"],
              input[type="password"],
              input[type="email"],
              select {
                padding: 10px;
                border: 1px solid rgb(205, 205, 205);
                border-radius: 5px;
                transition: 0.2s ease;
                outline: none;
                &:focus {
                  outline: 1px solid blue;
                }
                &:invalid[focused="true"] ~ .error-message {
                  display: block;
                }
                &:valid[focused="true"] {
                  outline: 1px solid blue;
                }
                &:invalid[focused="true"] {
                  outline: 1px solid red;
                }
              }
              small {
                color: rgb(179, 179, 179);
                font-size: 11px;
              }
              .error-message {
                font-size: 13px;
                color: red;
                display: none;
              }
            }
          }
        }
        .submitButton {
          padding: 10px 20px;
          border: none;
          cursor: pointer;
          height: 48px;
          width: 120px;
          background-color: #5b3016;
          color: rgb(255, 255, 255);
          border-radius: 3px;
          font-size: 12px;
          transition: all 0.3s ease-out 0s;
          &:hover {
            cursor: pointer;
            background: #5b3016;
          }
        }
        .notice{
          font-size: 1rem;
          color: #3a71d1;
          margin-top: 15px;
          max-width: 60%;
        }
        button {
          display: inline-flex;
          overflow: hidden;
          -webkit-box-align: center;
          align-items: center;
          position: relative;
          vertical-align: top;
          cursor: pointer;
          height: 38px;
          padding: 0px 15px;
          color: rgb(255, 255, 255);
          border-radius: 3px;
          font-size: 12px;
          transition: all 0.3s ease-out 0s;
          background: #5b3016;
          border: none;
          &:hover {
            cursor: pointer;
            background: #5b3016;
          }
        }
      }
    }
  }
  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      display: inline-flex;
      overflow: hidden;
      -webkit-box-align: center;
      align-items: center;
      position: relative;
      vertical-align: top;
      cursor: pointer;
      height: 30px;
      padding: 0px 15px;
      border-radius: 3px;
      font-size: 12px;
      transition: all 0.3s ease-out 0s;
      border: 1px solid rgb(0, 114, 187);
      color: rgb(0, 114, 187);
      z-index: 0;
      &:hover {
        background: rgb(0, 114, 187);
        color: white;
        cursor: pointer;
      }
    }
    .deleteButton {
        display: inline-flex;
        overflow: hidden;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        vertical-align: top;
        cursor: pointer;
        height: 30px;
        padding: 0px 15px;
        border-radius: 3px;
        font-size: 12px;
        transition: all 0.3s ease-out 0s;
        border: 1px solid rgb(216, 30, 91);
        color: rgb(216, 30, 91);
        z-index: 0;
      &:hover {
        background: rgb(216, 30, 91);
        color: white;
        cursor: pointer;
      }
    }
  }
}

// RESPONSIVENESS
@media screen and (max-width: 426px) {
  .main-container {

    .header {
      margin-bottom: 25px;
      h1 {
        font-size: 26px;
      }
    }
    .academic-year-details {
      display: flex;
      gap: 10px;
      font-size:14px;
    }
    .terms {
      margin-top: 30px;
      .terms-list {
        overflow: scroll;
        table {
          width: max-content !important;
          thead {
            border-bottom: 1px solid #f5f5f5;
            th {
              padding: 10px 27px;
            }
          }
          tbody {
            tr {
              transition: all 0.3s ease-out 0s;
              border-bottom: 1px solid #f5f5f5;
              td {
                padding: 10px 27px;
                text-align: left;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .disclaimer {
      h4 {
        font-size: 20px;
      }
      p{
        font-size: 15px;
      }
      ul {
        margin-top: 20px;
        list-style-type: decimal;
        margin-left: 20px;
        li {
          font-size: 15px;
          list-style-type: decimal;
          
          a {
            text-decoration: underline;
            color: #ffc26f;
          }
        }
      }
    }
    .bottom {
      flex-direction: column;
      gap: 20px;
     
      .right {
        form {
          
          .formSection {
            
            
            .formRow {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              gap: 22px;
              margin-bottom: 30px;
              
              .generate-password {
                font-size: 13px;
                padding: 9px;
                margin-top: 0px;
                
              }
  
              .formInput {
                label{
                  font-size: 15px;
                }
                .radioInline {
                  font-size:15px;
                }
              
               
                .error-message {
                  font-size: 11px;
                }
              }
            }
          }
         
        }
      }
    }
    
  }
}