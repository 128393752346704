.welcome-card{
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    border-radius: 16px;
    height: max-content;
    background-image: radial-gradient(circle at 85% 40%, rgba(204, 204, 204,0.08) 0%, rgba(204, 204, 204,0.08) 33.333%,rgba(188, 188, 188,0.08) 33.333%, rgba(188, 188, 188,0.08) 66.666%,rgba(171, 171, 171,0.08) 66.666%, rgba(171, 171, 171,0.08) 99.999%),radial-gradient(circle at 27% 80%, rgba(196, 196, 196,0.08) 0%, rgba(196, 196, 196,0.08) 33.333%,rgba(115, 115, 115,0.08) 33.333%, rgba(115, 115, 115,0.08) 66.666%,rgba(33, 33, 33,0.08) 66.666%, rgba(33, 33, 33,0.08) 99.999%),radial-gradient(circle at 95% 54%, rgba(223, 223, 223,0.08) 0%, rgba(223, 223, 223,0.08) 33.333%,rgba(168, 168, 168,0.08) 33.333%, rgba(168, 168, 168,0.08) 66.666%,rgba(112, 112, 112,0.08) 66.666%, rgba(112, 112, 112,0.08) 99.999%),linear-gradient(45deg, rgb(31, 80, 162),rgb(43, 81, 194));
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    .welcome-image{
        display: none;
        img{
            position: absolute;
            top: -19px;
            left: 0;
            // height: 100px;
        }
    }
    .text{
        width: 100%;
        h3{
            text-transform: uppercase;

            font-size: 24px;
            line-height: 29px;
            font-weight: 700;
            color: white;
        }
        p{
            font-size: 16px;
            line-height: 20px;
            color: #e6e6e6;
        }
        .issues{
            margin-top: 20px;
            color: #c00000;
            font-weight: 600;
            font-size: 18px;
           
        }
        .term-progress{
            margin-top: 10px;
            background-color: white;
            width: 100%;
            padding: 6px;
            border-radius: 5px;
            .title{
                color: #4E4E4E;
                font-weight: 600;
                font-size: 14px;
            }
            .term-progress-bar{
                height: 7px;
                width: 100%;
                background-color: #D9D9D9;
                border-radius: 5px;
                margin: 10px auto 5px;
                .filled{
                    height: 100%;
                    background-color: var(--base-clr);
                    border-radius: 5px;
                }
            }
            .term-definitions{
                display: flex;
                justify-content: space-between;
                font-weight: 400;
                p{
                    color: #4E4E4E;
                    font-size: 14px;
                }
            }
        }
    }

}

// RESPONSIVENESS
@media screen and (max-width: 426px) {
    .welcome-card{
        
        .welcome-image{
            display: none;
        }
        .text{
            h3{
                
    
                font-size: 20px;
                line-height: 20px;

            }
            .term-progress{
                .term-definitions{
                    flex-direction: column;
                }
            }
        }
    }
}