.stats-card{
    background-color: white;
    // background-image: url('../../assets/Ellipse.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    flex: 1;
    // display: flex;
    position: relative;
    gap: 7px;
    align-items: center;
    background: rgba(255, 255, 255, .05);
    border: 1px solid rgba(16, 121, 128, .2);
    box-shadow: 0 5px 10px rgba(0,0,0,.05);
    border-radius: 10px;
    padding: 20px 55px 20px 30px;
    
    .icon{
        position: absolute;
        top: 15px;
        right: 15px;
        background: var(--title-3);
        border-radius: 9px;
        padding: 4px 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 35px;
    }
    .text{
        .title{
            font-weight: 500;
            color: #B5B5B5;
            font-size: 15px;
            line-height: 17px;
        }
        .main-text{
            font-weight: 700;
            font-size: 35px;
            padding-top: 5px;
            line-height: 29px;
            color: rgb(111, 111, 111);
        }
    }
}

// RESPONSIVENESS
@media screen and (max-width: 426px) {
    .stats-card{
       
        flex: initial;
        background-position-x: center;
        
    }
    
}