.features-section {
  padding: 50px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  .features-title {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    h5 {
      font-weight: 500;
    }
    h2 {
      font-size: 40px;
    }
    p {
      font-weight: 300;
      margin-top: 10px;
    }
  }
  .features-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 67%;
    .feature {
      width: 250px;
      padding: 20px 10px;
      -webkit-box-shadow: 10px 10px 34px -5px rgba(0, 0, 0, 0.39);
      -moz-box-shadow: 10px 10px 34px -5px rgba(0, 0, 0, 0.39);
      box-shadow: 10px 10px 34px -5px rgba(0, 0, 0, 0.39);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .right {
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        margin: auto;
        padding: 10px;
        border-radius: 15px;
        color: white;
      }
      .left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .feature-list {
          .feature-list-item {
            display: flex;
            width: 100%;
            gap: 10px;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            p {
              width: 100%;
              font-size: 11px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1025px) {
    .features-section{
        .features-title{
            width: 50%;
        }
        
    }
}

@media screen and (max-width: 426px) {
    .features-section{
        .features-title{
            width: 80%;
            h2{
                font-size: 29px;
            }
            p{
                font-size: 14px;
            }
        }
        
    }
}