#contact{
    padding: 100px 250px;
    display: flex;
    height: max-content;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 245, 245);
    overflow: hidden;
    .contact-left{
        flex: 1 0 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
        .top{
            font-weight: 500;
            color: var(--secondaryColor)
        }
        h3{
            font-size: 40px;
            width: 75%;
        }
        .bottom{
            margin-top:20px;
            color: var(--secondaryColor);
            display: flex;
            align-items: center;
            gap: 10px;
            letter-spacing: 2px;
            .dot{
                height: 5px;
                width: 5px;
                background-color: rgb(255, 166, 0);
                border-radius: 100%;
            }
        }
    }
    .contact-right{
        flex: 1 0 40%;
        width: 100%;
        height: 100%;
        .contact-form{
            display: flex;
            height: 100%;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            justify-content: center;
        }
       

        .input-data{
            width: 100%;
            position: relative;
            
            input{
                padding: 20px 0px 5px 10px;
                width: 100%;
                outline: none;
                border: 1px solid rgb(208, 208, 208);
                border-radius: 5px;
                &:focus, &:valid{
                    outline: 1px solid #acb0fa;
                }
                &:focus ~ label ,&:valid ~ label{
                    transform: translateY(-13px);
                    font-size: 12px;
                }
                
            }
            textarea{
                width: 100%;
                padding: 20px 10px;
                border: 1px solid rgb(208, 208, 208);
                border-radius: 5px;
                outline: none;
                &:focus, &:valid{
                    outline: 1px solid #acb0fa;
                }
                &:focus ~ .text-area-label ,&:valid ~ .text-area-label{
                    transform: translateY(-13px);
                    font-size: 12px;
                }
            }
            label{
                position: absolute;
                bottom: 13px;
                font-size: 13px;
                left: 10px;
                width: max-content;
                transition: .4s ease;
            }
            .text-area-label{
                top: 16px;
            }
        }
        .submit{
            width: 100%;
            .send-btn{
                width: 100%;
                padding: 8px 100px;
                background: none;
                border: 1px solid rgb(20, 20, 255);
                color:rgb(20, 20, 255);
                border-radius: 5px;
                font-size: 15px;
                transition: .8s ease;
                &:hover{
                    background:rgb(20,20,255);
                    color: white;
                    cursor: pointer;
                }
            }
            .loading-btn{
                width: 100%;
                padding: 8px 100px;
                background:rgb(145, 145, 145);
                border: 1px solid rgb(73, 73, 73);
                color: white;
                border-radius: 5px;
                font-size: 15px;
            }
        }
    }
}


@media screen and (max-width:1024px) {
    #contact{
        padding: 40px 100px;
        .contact-left{
            h3{
                font-size: 35px;
            }
            .bottom{
                letter-spacing: 0px;
                font-size: 15px;
            }
        }
    }
}

@media screen and (max-width:768px) {
    #contact{
        padding: 50px 100px;
        .contact-left{
            h3{
                font-size: 24px;
            }
            .bottom{
                letter-spacing: 0px;
                font-size: 13px;
            }
        }

        .contact-right{
            flex: 1 0 40%;
            
            height: 100%;
            .contact-form{
                display: flex;
                height: 100%;
                flex-direction: column;
                gap: 7px;
                align-items: flex-start;
                justify-content: center;
            }
           
    
            .input-data{
                width: 350px;
                position: relative;
                
                input{
                    padding: 20px 0px 5px 10px;
                    width: 100%;
                    outline: none;
                    border: 1px solid rgb(208, 208, 208);
                    border-radius: 5px;
                    &:focus, &:valid{
                        outline: 1px solid #acb0fa;
                    }
                    &:focus ~ label ,&:valid ~ label{
                        transform: translateY(-13px);
                        font-size: 12px;
                    }
                    
                }
                textarea{
                    width: 97%;
                    height: 70px;
                    padding: 20px 10px;
                    border: 1px solid rgb(208, 208, 208);
                    border-radius: 5px;
                    outline: none;
                    &:focus, &:valid{
                        outline: 1px solid #acb0fa;
                    }
                    &:focus ~ .text-area-label ,&:valid ~ .text-area-label{
                        transform: translateY(-13px);
                        font-size: 12px;
                    }
                }
                label{
                    position: absolute;
                    bottom: 13px;
                    font-size: 13px;
                    left: 10px;
                    width: max-content;
                    transition: .4s ease;
                }
                .text-area-label{
                    top: 16px;
                }
            }
            
        }
    }
}

@media screen and (max-width:426px) {
    #contact{
        flex-direction: column;
        height: max-content;
        gap: 30px;
        padding: 50px 20px;
        
        .contact-left{
            align-items: center;
            text-align: center;
            width: 100%;
            
            .top{
                font-size: 14px;
                width: 100%;
            }
            h3{
                font-size: 22px;
                width: 100%;
            }
            .bottom{
                letter-spacing: 0px;
                font-size: 13px;
                align-items: center;
            }
        }

        .contact-right{
            flex: 1 0 40%;
            height: 100%;
            .contact-form{
                display: flex;
                height: 100%;
                flex-direction: column;
                gap: 7px;
                align-items: flex-start;
                justify-content: center;
            }
           
    
            .input-data{
                width: 100%;
                position: relative;
                
                input{
                    padding: 20px 0px 5px 10px;
                    width: 100%;
                    outline: none;
                    border: 1px solid rgb(208, 208, 208);
                    border-radius: 5px;
                    &:focus, &:valid{
                        outline: 1px solid #acb0fa;
                    }
                    &:focus ~ label ,&:valid ~ label{
                        transform: translateY(-13px);
                        font-size: 12px;
                    }
                    
                }
                textarea{
                    width: 97%;
                    height: 70px;
                    padding: 20px 10px;
                    border: 1px solid rgb(208, 208, 208);
                    border-radius: 5px;
                    outline: none;
                    &:focus, &:valid{
                        outline: 1px solid #acb0fa;
                    }
                    &:focus ~ .text-area-label ,&:valid ~ .text-area-label{
                        transform: translateY(-13px);
                        font-size: 12px;
                    }
                }
                label{
                    position: absolute;
                    bottom: 13px;
                    font-size: 13px;
                    left: 10px;
                    width: max-content;
                    transition: .4s ease;
                }
                .text-area-label{
                    top: 16px;
                }
            }
            
        }
    }
}

@media screen and (max-width:376px) {
    #contact{
        
        .contact-left{
            
            .bottom{
                
                flex-direction: column;
            }
        }

        
    }
}