.registration-forms {
  width: 90%;
  margin: 50px auto;
  padding: 40px 0px;
  -webkit-box-shadow: 10px 10px 117px -33px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 10px 10px 117px -33px rgba(0, 0, 0, 0.49);
  box-shadow: 10px 10px 117px -33px rgba(0, 0, 0, 0.49);
  border-radius: 20px;
  .form-body {
    margin-top: 50px;
    .form {
      .MuiBox-root {
        padding: 16px;
      }
      .form-body {
        display: flex;
        width: 85%;
        margin: auto;
        gap: 20px;
        justify-content: center;
        align-items: center;
        .svg {
          flex: 1;
          img {
            height: 240px;
          }
        }
        .main-form {
          flex: 2;
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding: 30px;
          .form-title {
            h6 {
              line-height: 35px;
              font-size: 18px;
            }
            p {
              font-size: 14px;
            }
          }
          .form-fields {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .input-box {
              width: 100%;
              label {
                text-transform: uppercase;
                font-weight: 700;
                display: block;
                margin: 0 0 5px;
                font-size: 0.66em;
              }
              input[type=text], input[type=email], input[type=tel],input[type=password],
              select {
                height: 42px;
                width: 100%;
                line-height: 150%;
                padding-left: 10px;
                padding-right: 10px;
                background-color: rgb(255, 255, 255);
                border: 1px solid rgb(216, 216, 227);
                border-radius: 3px;
                outline: none;
                transition: all 0.3s ease-out 0s;
                box-sizing: border-box;
                color: rgb(56, 58, 63);
                &:focus{
                  outline: 1px solid blue;
                }
                &:invalid[focused = "true"] ~ .error-message{
                    display: block;
                }
                &:valid[focused="true"]{
                    outline: 1px solid blue;
                }
                &:invalid[focused="true"]{
                    outline: 1px solid red;
                }
              }
              small{
                color:rgb(179, 179, 179);
                font-size: 11px;
              }
              .error-message{
                  font-size: 13px;
                  color: red;
                  display: none;
              }

              .instructions {
                font-size: 0.75rem;
                border-radius: 0.5rem;
                color: red;
                position: relative;
                bottom: -10px;
                transition: 0.4s ease-in-out;
              }
              .offscreen {
                position: absolute;
                left: -9999px;
              }
            }
          }
        }
      }
    }
    // padding: 30px;
    // .svg{
    //     flex: 1;
    //     img{

    //         height: 240px;
    //     }
    // }
    // .form{
    //     flex: 2;
    //     width: 72%;
    //     display: flex;
    //     gap: 15px;
    //     flex-direction: column;
    //     text-align: left;
    //     .form-title{
    //         h6{
    //             line-height: 35px;
    //             font-size: 18px;
    //             font-weight: bold;
    //         }
    //         p{
    //             font-size: 14px;
    //         }
    //     }
    //     .form-fields{
    //         width: 100%;
    //         .MuiFormControl-root{
    //             margin-right: 10px;
    //         }
    //         .input-field{
    //             background-color: white;
    //         }
    //     }
    // }
  }
}

@media screen and (max-width: 426px) {
  .registration-forms{
    .form-body{
      .form{
        .form-body{
          flex-direction: column;
          .svg{
            img{
              height: 200px;
            }
          }
          .main-form{
            .form-title{
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}