.school-profile-body{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.school-profile-left{
    h4{
        font-weight: 400;
        font-size: 14px;
        display: block;
        color: rgb(56, 58, 63);
    }
    
    .formSection{
        margin-bottom: 50px;
        .formRowHeader{
            font-size: 14px;
            display: block;
            color: rgb(56, 58, 63);
        }
        .formRow{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 25px;
            margin-bottom: 30px;
            
            .formInput{
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 5px;
                .radioInline{
                    display: flex;
                    gap: 5px;
                }
                label{
                    text-transform: uppercase;
                    position: relative;
                    font-weight: 700;
                    display: block;
                    font-size: 0.9em;
                }
                .upload-button{
                    width: max-content;
                    display: inline-flex;
                    gap: 5px;
                    overflow: hidden;
                    -webkit-box-align: center;
                    align-items: center;
                    position: relative;
                    vertical-align: top;
                    cursor: pointer;
                    height: 38px;
                    background-color: #5b3016;
                    padding: 0px 15px;
                    color: rgb(255, 255, 255);
                    border-radius: 3px;
                    font-size: 12px;
                    transition: all 0.3s ease-out 0s;
                    &:hover{
                        background-color:#5b3016;
                    }
                }
                input[type=file]{
                    display: none;
                }
                
                input[type=tel],input[type=text],input[type=date],input[type=password],input[type=email], select{
                    padding: 10px;
                    border: 1px solid rgb(205, 205, 205);
                    border-radius: 5px;
                    transition: .2s ease;
                    outline: none;
                    &:focus{
                        outline: 1px solid blue;
                    }
                    &:invalid[focused = "true"] ~ .error-message{
                        display: block;
                    }
                    &:valid[focused="true"]{
                        outline: 1px solid blue;
                    }
                    &:invalid[focused="true"]{
                        outline: 1px solid red;
                    }
                }
                small{
                    color:rgb(179, 179, 179);
                    font-size: 11px;
                }
                .error-message{
                    font-size: 13px;
                    color: red;
                    display: none;
                }
            }
        }
    }
    .formInput{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 5px;
        .radioInline{
            display: flex;
            gap: 5px;
        }
        label{
            font-weight: 600;
            color: #202020;
        }
        input[type=tel],input[type=text],input[type=date],input[type=password],input[type=email], select{
            padding: 10px;
            border: 1px solid rgb(205, 205, 205);
            border-radius: 5px;
            transition: .2s ease;
            outline: none;
            &:focus{
                outline: 1px solid blue;
            }
            &:invalid[focused = "true"] ~ .error-message{
                display: block;
            }
            &:valid[focused="true"]{
                outline: 1px solid blue;
            }
            &:invalid[focused="true"]{
                outline: 1px solid red;
            }
        }
        small{
            color:rgb(179, 179, 179);
            font-size: 11px;
        }
        .error-message{
            font-size: 13px;
            color: red;
            display: none;
        }
    }
    button{
        display: inline-flex;
        overflow: hidden;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        vertical-align: top;
        cursor: pointer;
        height: 38px;
        padding: 0px 15px;
        color: rgb(255, 255, 255);
        border-radius: 3px;
        font-size: 12px;
        transition: all 0.3s ease-out 0s;
        float: right;
        background: #5b3016;
        border: none;
       
        &:hover{
            cursor: pointer;
            background: #5b3016;
        }
    }
}
.school-profile-right{
    flex: 1.5;
    height:max-content;
    border: 1px dashed rgb(157, 157, 183);
    padding: 30px;
    border-radius: 10px;
    h4{
        font-size: 14px;
        display: block;
        font-weight: 400;
        color: rgb(56, 58, 63);
    }
    .school-details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        gap: 10px;
        h2{
            font-size: 25px;
            display: block;
            font-weight: bold;
            text-align: center;
        }
        .school-logo{
            width: 100px;
            border-radius: 16%;
            
        }
    }
    .school-location{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        .columns{
            display: flex;
            justify-content: space-between;
            gap: 10px;
            align-items: flex-start;
            width: 100%;
            flex-direction: column;
            margin-top: 20px;
        }
    }
    .action-buttons{
        display: flex;
        width:100%;
        justify-content: center;
        margin-top: 50px;
        gap: 10px;
        button{
            display: inline-flex;
            overflow: hidden;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            vertical-align: top;
            cursor: pointer;
            padding: 10px 15px;
            border-radius: 3px;
            font-size: 12px;
            transition: all 0.3s ease-out 0s;
            border: 1px solid rgb(0, 114, 187);
            color: rgb(0, 114, 187);
            background-color: transparent;
            &:hover{
                background-color: rgba(1, 154, 250,.2);
            }
        }
        .suspend{
            font-size: 15px;
            padding: 10px 20px;
            background-color: blue;
            border: none;
            color: white;
            cursor: pointer;
            
        }
        .delete{
            font-size: 15px;
            padding: 10px 20px;
            background-color: red;
            border: none;
            color: white;
            cursor: pointer;
        }
    }
}

.grading-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    form{
        width: 80%;
        table{
            border-spacing:12px;
            width:100%;
            input[type=text],input[type=date],input[type=password],input[type=email], select{
                
                font-size: 14px;
                border-radius: 2px;
                border: 1px solid #cccccc;
                padding: 10px;
                transition: .2s ease;
                outline: none;
                width:100%;
                &:focus{
                    outline: 1px solid blue;
                }
            }
        }
    }

    
}

#reset-password{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: auto;
    flex-wrap: wrap;
    .left-image{
        flex: 1;
        img{
            height: 400px;
        }
    }
    .right-forms{
        flex: 1;
        .header{
            margin-bottom: 20px;
            p{
                font-size: 12px;
                color: rgb(139, 139, 139);
            }
        }
        input[type=email], input[type=number], input[type=password]{
            width: 100%;
            border: 1px solid rgb(147, 147, 147);
            border-radius: 5px;
            outline: none;
            padding: 10px;
            margin-bottom: 10px;
            &:focus{
                border: 1px solid rgb(2, 82, 114);
            }
        }
        .resend-code{
            text-align: right;
            font-size: 13px;
            margin-bottom: 20px;
            i{
                &:hover{
                    cursor: pointer;
                    color:rgb(0, 139, 194)
                }
            }
        }
        .error-message{
            color: red;
            margin-bottom: 20px;
        }
        button, input[type=submit]{
            align-items: center;
            justify-content: center;
            gap: 5px;
            display: flex;
            width: 50%;
            background-color: #35AC46;
            border: none;
            color: white;
            padding: 10px;
            margin-top: 10px;
            font-size: 17px;
            margin-bottom: 50px;
            transition: 1s ease;
            &:hover{
                cursor: pointer;
                background-color: #2d983d;
            }
        }
        .back-to-login{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #bbb;
        }
    }
}

// RESPONSIVENESS
@media screen and (max-width: 426px) {
    .school-profile-body{
        flex-direction: column-reverse;
    }
}