.debtors-list{
    margin-top: 30px;
    .print-button{
        padding: 10px 20px;
        color: #045A8D;
        background-color: white;
        border: 1px solid #045A8D;
        border-radius: 5px;
        width: max-content;
        transition: .3s ease;
        &:hover{
            cursor: pointer;
            background-color: #95d8ff6a;
        }
    }
    .debtors-table{
        
        border-collapse: collapse;
        width: 90%;
        margin: 30px auto;
        thead{
            background-color: #EEF8FF;
            border: 1px solid #9FA7B5;
            th{
                padding: 14px 5px;
                color: #045A8D;
                font-weight: bold;
                text-align: left;
                &:nth-child(3),&:nth-child(4), &:nth-child(5){
                    text-align: right;
                }
            }
            
        }
        tbody{
            tr{
                border: 1px solid #9FA7B5;
                td{
                    padding: 14px 5px;
                    font-weight: bold;
                }
            }
            .debtor{
                .debtor-id{
                    font-weight: 400;
                }
            }
            .total-amount, .amount-paid, .outstanding-balance{
                text-align: right;
            }
            .amount-paid{
                color: #2A963B;
            }
            .outstanding-balance{
                color: #FD3939;
            }
        }
    }
}

@media screen and (max-width: 426px){
    .debtors-list{
        overflow: scroll;
        .debtors-table{
            th{
                font-size: 10px!important;
            }
            td{
                font-size: 12px!important;
            }
        }
    }
}