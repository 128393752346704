.register-modal{
    width: clamp(50%, 65vw, 90%);
    height: auto;
    background-color:  rgb(245, 245, 247);
    color: rgb(56, 58, 63);
    margin: auto;
    padding: 0 2rem;
    border-radius: 5px;
    position: absolute;
    
    display: flex;
    flex-direction: column;
    gap: 10px;
    .logo{
        padding:20px 30px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo-img{
            height: 40px;
        }
    }
    .modal-body{
        .form{
            .MuiBox-root{
                padding: 16px;
            }
            .form-body{     
                display: flex;   
                width: 85%;
                margin: auto;
                gap: 20px;
                justify-content: center;
                align-items: center;
                .svg{
                    flex: 1;
                    img{
                        
                        height: 240px;
                    }
                }
                .main-form{
                    flex: 2;
                    display: flex; 
                    flex-direction: column;
                    gap: 15px;
                    padding: 30px;
                    .form-title{
                        h6{
                            line-height: 35px;
                            font-size: 18px;
                        }
                        p{
                            font-size: 14px;
                        }
                    }
                    .form-fields{
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        .input-box{
                            
                            width: 100%;
                            label{
                                text-transform: uppercase;
                                font-weight: 700;
                                display: block;
                                margin: 0 0 5px;
                                font-size: 0.66em;
                            }
                            input, select{
                                height: 42px;
                                width: 100%;
                                line-height: 150%;
                                padding-left: 10px;
                                padding-right: 10px;
                                background-color: rgb(255, 255, 255);
                                border: 1px solid rgb(216, 216, 227);
                                border-radius: 3px;
                                outline: none;
                                transition: all 0.3s ease-out 0s;
                                box-sizing: border-box;
                                color: rgb(56, 58, 63);
                                &:focus, &:valid{
                                    border: 1px solid #1976d2;
                                }
                            }
                            
                            .instructions{
                                font-size: 0.75rem;
                                border-radius: 0.5rem;
                                color: red;
                                position: relative;
                                bottom: -10px;
                                transition: .4s ease-in-out;
                            }
                            .offscreen {
                                position: absolute;
                                left: -9999px;
                            }
                        }
                    }
                }
            }
        }
        // padding: 30px;
        // .svg{
        //     flex: 1;
        //     img{
                
        //         height: 240px;
        //     }
        // }
        // .form{
        //     flex: 2;
        //     width: 72%;
        //     display: flex;
        //     gap: 15px;
        //     flex-direction: column;
        //     text-align: left;
        //     .form-title{
        //         h6{
        //             line-height: 35px;
        //             font-size: 18px;
        //             font-weight: bold;
        //         }
        //         p{
        //             font-size: 14px;
        //         }
        //     }
        //     .form-fields{
        //         width: 100%;
        //         .MuiFormControl-root{
        //             margin-right: 10px;
        //         }
        //         .input-field{
        //             background-color: white;
        //         }
        //     }
        // }
    }
}


