
#sidebar-container{
    // background-color: #ddf1ff;
    
    position: fixed;
    left: 0;
    flex: 1;
    height: 100%;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    gap: 35px;
    z-index: 99;
    .top-section{
        display: flex;
        align-items: center;
        justify-content: center;
        
        .bars{
            font-size: 25px;
            &:hover{
                cursor: pointer;
            }
        }
    }
    .sidebar-menu{
        display: flex;
        flex-direction: column;
          
        height: 100%;
        overflow-y: auto;
        margin-bottom: 60px;
        &::-webkit-scrollbar{
            width: .5vw;
        }
        &::-webkit-scrollbar-thumb{
            background-color: var(--primary-clr-2);
            border-radius: 20px;
        }
        &::-webkit-scrollbar-track{
            background-color: rgba(216, 216, 216,.3);
        }
        
        
    }
    .side-nav-bottom{
        .profile-pic{
            img{
                height: 40px;
                border-radius: 50%;
            }
        }
        margin-bottom: 60px;
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 10px;
        background-color: #cbecff;
        .details{
            h3{
                font-size: 13px;
                transition: .4s ease-in-out;
                &:hover{
                    color: rgb(15, 15, 143)
                }
            }
            p{
                font-size: 12px;
                color: rgb(82, 82, 82);
                
            }
            &:hover{
                cursor: pointer;
            }
        }
        .logout{
            font-size: 20px;
            margin-left: 10px;
            cursor: pointer;
            transition: .5s ease-in-out;
            &:hover{
                color: green;
            }
        }
    }
}


// RESPONSIVE
@media screen and (max-width: 426px) {
    #sidebar-container{
        z-index: 110; 
        top: 52px;
    }
}

@media screen and (max-width: 769px) {
    #sidebar-container{
        // display: none;
    }
}