.finance{
    display: flex;
    flex-wrap: wrap;
    .left-finance{
        flex: 2;
        border-right: 1px solid #ccc;
        
        padding-right: 30px;
        .cards{
            
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            .finance-card{
                flex: 1;
                padding:20px 10px;
                border-radius: 5px;
                
                .icon{
                    background-color: black;
                    color: white;
                    padding: 5px;
                    border-radius: 50%;
                }
                
            }
        }
        
        .expenses-chart{
            margin: 30px 0;
            padding: 10px 20px;
            border: 1px solid #ccc;
            border-radius: 5px;
            .title{
                font-weight: 700;
            }
        }

    }
    .right-finance{
        flex: 1;
        padding: 10px 20px;
        .buttons{
            display: flex;
            justify-content: space-between;
            button{
                margin-bottom: 10px;
                padding: 10px;
                background-color: #2E8A99;
                border: none;
                color: white;
                transition: .5s ease-in-out;
                display: flex;
                align-items: center;
                gap: 10px;
                span{
                    background-color: white;
                    color:#2E8A99;
                    padding: 3px 7px;
                    border-radius: 50%;
                    font-size: 14px;
                }
                &:hover{
                    cursor: pointer;
                    background-color: #3bb1c4;
                }
            }
        }
        .overview-pie{
            padding: 10px 20px;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-bottom: 20px;
            .header{
                display: flex;
                justify-content: space-between;
            }
            .transactions{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .transaction{
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #eee;
                    padding: 5px 0;
                    align-items: center;
                    .details{
                        display: flex;
                        flex-direction: column;
                        p{
                            font-size: 12px;
                        }
                    }
                    .amount{
                        font-size: 14px;
                        font-weight: 500;
                    }

                }
            }
        }
    }
}

.add-form{
    padding: 20px;
        .loading-btn{
            display: flex;
            gap: 10px;
            align-items: center;
            width: max-content;
            padding: 10px 20px;
            background: #d8d8d8;
            border: none;
            color: white;
            font-size: 20px;
            &:hover{
                cursor: default;
            }
        }
        .submitButton {
            display: inline-flex;
            overflow: hidden;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            vertical-align: top;
            cursor: pointer;
            height: 38px;
            padding: 0px 15px;
            color: rgb(255, 255, 255);
            border-radius: 3px;
            font-size: 12px;
            transition: all 0.3s ease-out 0s;
            margin-top: 30px;
            background: #5b3016;
            border: none;
            &:hover{
              cursor: pointer;
              background: #5b3016;
          }
          }
        .formRow{
            display: flex;
            gap: 30px;
            align-items: flex-start;
        }
        .formInput{
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-top: 20px;
            .radioInline{
                display: flex;
                gap: 5px;
            }
            label{
                font-weight: 500;
                text-transform: uppercase;
                margin-top: 15px;
            }
            textarea, input[type=time],input[type=text],input[type=number],input[type=date],input[type=password],input[type=email], select{
                padding: 10px;
                border: 1px solid rgb(205, 205, 205);
                border-radius: 5px;
                transition: .2s ease;
                outline: none;
                &:focus{
                    outline: 1px solid blue;
                }
                &:invalid[focused = "true"] ~ .error-message{
                    display: block;
                }
                &:valid[focused="true"]{
                    outline: 1px solid blue;
                }
                &:invalid[focused="true"]{
                    outline: 1px solid red;
                }
            }
            small{
                color:rgb(179, 179, 179);
                font-size: 11px;
            }
            .error-message{
                font-size: 13px;
                color: red;
                display: none;
            }
            .formRow{
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-wrap: wrap;
                gap: 10px;
                div{
                    flex: 1;
                }
            }
        }
}

// RESPONSIVENESS
@media screen and (max-width: 426px) {
    .add-form{
        .formRow{
            flex-wrap: wrap;
            gap: 1px;
            .formInput{
                margin-top: 0px;
            }
        }
    }
}