.hidden{
    display: none;
}
body > iframe {
    /* display: none; */
}
/* ::-webkit-scrollbar {
    width: 20px;
  }
    ::-webkit-scrollbar-track {
    background-color: rgba(216, 216, 216,.3);
  }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(104, 104, 255,.3);
      border-radius:10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    
    background: #8CABFF; 
  }  */
.progress-bar{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
a{
    color: inherit;
    text-decoration: none;
}
.remove-button{
    display: inline-flex;
    overflow: hidden;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    height: 35px;
    padding: 0px 15px;
    border-radius: 3px;
    font-size: 12px;
    transition: all 0.3s ease-out 0s;
    border: 1px solid rgba(255, 102, 102,.5);
    color: #FF6666;
    z-index: 0;
}
.remove-button:hover{
    background-color: rgba(255, 102, 102,.2);;
}

.green-badge{
    -webkit-box-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
    height: auto;
    white-space: nowrap;
    width: auto;
    text-transform: uppercase;
    background: rgba(44, 218, 157, 0.15);
    color: rgb(24, 136, 97);
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    padding: 0px 8px;
    text-overflow: ellipsis;
    line-height: 1rem;
    word-break: break-word;
}

.red-badge{
    -webkit-box-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
    height: auto;
    white-space: nowrap;
    width: auto;
    text-transform: uppercase;
    background: rgba(216, 30, 91, 0.15);
    color: rgb(126, 18, 53);
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    padding: 0px 8px;
    text-overflow: ellipsis;
    line-height: 1rem;
    word-break: break-word;
}

.gray-badge{
    -webkit-box-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
    height: auto;
    white-space: nowrap;
    width: auto;
    text-transform: uppercase;
    background: rgba(69, 52, 58, 0.15);
    color: rgb(104, 99, 100);
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    padding: 0px 8px;
    text-overflow: ellipsis;
    line-height: 1rem;
    word-break: break-word;
}

.yellow-badge{
    -webkit-box-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
    height: auto;
    white-space: nowrap;
    width: auto;
    text-transform: uppercase;
    background: rgba(173, 136, 40, 0.15);
    color: rgb(156, 144, 67);
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    padding: 0px 8px;
    text-overflow: ellipsis;
    line-height: 1rem;
    word-break: break-word;
}

.badge{
    -webkit-box-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
    height: auto;
    white-space: nowrap;
    width: auto;
    text-transform: uppercase;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    padding: 0px 8px;
    text-overflow: ellipsis;
    line-height: 1rem;
    word-break: break-word;

}

.badge.blue-badge{
    background: rgba(0, 114, 187, 0.15);
    color: rgb(0, 114, 187);
}

.classic-button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    height: 37px;
    background-color: #107980;
    padding: 0px 15px;
    color: rgb(255, 255, 255);
    border-radius: 3px;
    font-size: 12px;
    transition: all 0.3s ease-out 0s;
    
  }
.classic-button:hover{
    background-color: rgba(0, 115, 187, 0.712)
}

.classic-button-outline{
    display: inline-flex;
    align-items: center;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    height: 30px;
    padding: 0px 15px;
    border-radius: 3px;
    font-size: 12px;
    transition: all 0.3s ease-out 0s;
    border: 1px solid #0072bb;
    color: #0072bb;
    z-index: 0;
}

.classic-button-outline:hover{
    background: #0072bb;
    color: white;
    cursor: pointer;
}

.viewButton {
    display: inline-flex;
    overflow: hidden;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    height: 30px;
    padding: 0px 15px;
    border-radius: 3px;
    font-size: 12px;
    transition: all 0.3s ease-out 0s;
    border: 1px solid rgb(0, 114, 187);
    color: rgb(0, 114, 187);
    z-index: 0;
    &:hover {
      background: rgb(0, 114, 187);
      color: white;
      cursor: pointer;
    }
}

.pagination-container{
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
}
.pagination {
    display: flex;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
  }
  
  .pagination .pagination-active-page {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
  }
  
  .pagination a:hover:not(.pagination-active-page) {background-color: #ddd;}

@media screen and (max-width: 426px){
    .scroll-table{
        overflow: scroll;
    }
}