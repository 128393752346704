.table{
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    .cell-wrapper{
        display: flex;
        align-items: center;
        .cell-image{
            height: 32px;
            width: 32px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
}