#register{
    background-color: #d6edfc;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 140px 0;
    gap: 10px;
    h5{
        font-weight: 400;
    }
    h3{
        font-size: 43px;
        width: 50%;
        text-align: center;
    }
    .button{
        margin-top: 30px;
        background: #425DF8;
        padding: 20px;
        font-weight: 600;
        border-radius: 7px;
        &:hover{
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 426px) {
    #register{
        h3{
            font-size: 26px;
            width: 80%;
        }
    }
    
}