.dashboard-container{
    flex: 1;
    padding: 1px 30px;
    width: 100%;
    .body{
        // display: flex;
        // flex-wrap: wrap;
        width: 100%;
        height: 100%;
        gap: 20px;
        .left{
            // flex: 2;
            padding: 5px 20px;
            flex-wrap: wrap;
            // width: 60%;
            .alert{
                padding: 1rem 10px;
                background-color: #f8d7da;
                border: 1px solid #f1aeb5;
                color: #58151c;
                border-radius: 0.375rem;
                margin-bottom: 1rem;
            }
            .cards{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                align-items: center;
                
                margin-top: 20px;
                gap: 10px;
            }
            .today-attendance{
                padding: 20px;
                margin-top: 20px;        
                border: 1px solid #e6e6e6;
                .attendance-top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .attendance-link{
                        border: 1px solid #e6e6e6;
                        border-radius: 5px;
                        padding: 10px;
                        text-transform: capitalize;
                        transition: .3s ease;
                        &:hover{
                            background-color: #c2e3ff;
                        }
                    }
                }
                .chart-data{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 300px;
                }
            }
            
        }
        .right{
            // flex: 1;
            // width: auto;
            width: 20%;
            .events-card{
                background: #576086;
                border-radius: 19px;
                width: 334px;
                height: max-content;
                padding: 20px 25px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title{
                        font-size: 24px;
                        color: white;
                    }
                    .add-events-btn{
                        background-color: #F7B697;
                        font-size: 12px;
                        font-weight: 600;
                        padding: 10px;
                        border-radius: 30px;
                        color: white;
                        transition: .3s ease;
                        &:hover{
                            cursor: pointer;
                            background-color: #f19c74;
                        }
                    }
                }
                .events{
                    
                    .no-event{
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 50px;
                        gap: 10px;
                        justify-content: center;
                        color: white;
                        button{
                            background-color: #F7B697;
                            font-size: 16px;
                            font-weight: 600;
                            padding: 10px 30px;
                            border: none;
                            border-radius: 10px;
                            color: white;
                            transition: .3s ease;
                            &:hover{
                                cursor: pointer;
                                background-color: #f19c74;
                            }
                        }
                    }
                    .event{
                        padding-bottom: 12px;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        .event-details{
                            padding: 2px 13px;
                            border-left: 1px solid #F7B697;
    
                            .event-title{
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 24px;
                                color: #F7B697;
                            }
                            .event-description{
                                font-weight: 300;
                                font-size: 13px;
                                line-height: 15px;
                                color: #fff;
                            }
                        }
                        .date{
                            color: #fff;
                            font-size: 20px;
                            line-height: 24px;
                            text-align: center;
                            .day{
                                font-weight: 500;
                            }
                            .month{
                                font-size: 26px;
                                font-weight: 600;
                                padding: 3px 0px;
                            }
                        }
                    }
                }
            }
            .chart-card{
                .title{
                    color: #567086;
                    font-size: 21px;
                    font-weight: 700;
                    line-height: 26px;
                }
                margin-top: 37px;
                width: 334px;
                height: 397px;
                background: #F7B697;
                border-radius: 19px;
                padding: 25px 17px;
            }
        }
    }
    .list-container{
        border: 1px solid var(--primary-clr);
        border-radius: 5px;
        padding: 20px;
        margin-top: 20px;
        .list-title{
            font-weight: 500;
            color: gray;
            margin-bottom: 15px;
        }
    }
}


@media screen and (max-width: 1025px){
    .body{
        flex-direction: column;
        .left{
            width:-webkit-fill-available!important;
        }
        .right{
            .events-card{
                width: auto;
            }
            .chart-card{
                width: auto;
            }
        }
    }
}

@media screen and (max-width: 426px) {
    .container{
        padding-left: 0 !important;
    
    }
    .dashboard-container{
        padding: 0;
        .body{
            .right{
                padding: 5px 20px;
                width: -webkit-fill-available;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .container{
        padding-left: 0 !important;
    }
    .body{
        flex-direction: column;
        .left{
            width: auto;
        }
        .right{
            .events-card{
                width: auto;
            }
            .chart-card{
                width: auto;
            }
        }
    }
}

