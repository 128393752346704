.positions-container {
  border: 1px solid rgb(230, 230, 230);
  height: max-content;
  border-radius: 10px;
  .tabs {
    display: flex;
    .tab {
      padding: 15px 20px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &:hover {
        color: #5b3016;
      }
    }
    .active-tab {
      padding: 15px 20px;
      background-color: white;
      border-bottom: 1px solid #5b3016;
      cursor: default !important;
      color: #5b3016;
    }
  }
  .content {
    display: none !important;
  }
  .show-content {
    .schedule-body {
      display: flex;
      flex-direction: column;
      .tab-cards {
        margin: 30px auto;
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        
        .tab-card {
          flex: 1;
          -webkit-box-align: center;
          align-items: center;
          border-radius: 5px;
          color: rgb(0, 0, 0);
          background: rgb(255, 255, 255);
          
          transition: .2s ease-out;
          padding: 20px;
          &:nth-child(1){
            border: 1px solid #213363;
            .icon{
              background-color: #213363;
            }
          }
          &:nth-child(2){
            border: 1px solid #85A389;
            .icon{
              background-color: #85A389;
            }
          }
          &:nth-child(3){
            border: 1px solid #F29727;
            .icon{
              background-color: #F29727;
            }
          }
          &:hover{
            cursor: pointer;
            box-shadow: rgba(40, 40, 40, 0.2) 1px 3px 6px;
          }
          .icon {
            
            color: white;
            padding: 5px;
            border-radius: 50%;
          }
          h3{
            font-size: 22px;
            display: block;
            font-weight: bold;
          }
          p{
            margin-top: 5px;
            color: rgb(114, 114, 114);
            font-size: 12px;
          }
        }
      }
      .tab-top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .tab-top-left {
          flex: 2;
        }
        .tab-top-right {
          display: flex;
          align-items: center;
          flex: 1;
          gap: 20px;
          .print-button {
            display: inline-flex;
            overflow: hidden;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            vertical-align: top;
            cursor: pointer;
            height: 37px;
            background-color: rgb(0, 114, 187);
            padding: 0px 15px;
            color: rgb(255, 255, 255);
            border-radius: 3px;
            font-size: 12px;
            transition: all 0.3s ease-out 0s;
            &:hover {
              background-color: rgba(0, 114, 187, 0.9);
            }
          }
          .search-box {
            input {
              width: 100%;
              height: 42px;
              line-height: 150%;
              padding-left: 10px;
              padding-right: 10px;
              background-color: rgb(255, 255, 255);
              border: 1px solid rgb(216, 216, 227);
              border-radius: 3px;
              outline: none;
              transition: all 0.3s ease-out 0s;
              box-sizing: border-box;
              color: rgb(56, 58, 63);
              &:focus {
                border: 1px solid rgb(59, 130, 246);
              }
            }
          }
        }
      }
      .tab-bottom {
        display: block;
        margin: 20px 0px;
        font-size: 15px;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(208, 213, 217);
        border-radius: 5px;
        table {
          background-color: rgb(255, 255, 255);
          border-collapse: collapse;
          border-radius: 5px;
          width: 100% !important;
          thead {
            tr {
              th {
                text-align: left;
                font-weight: bold;
                padding: 20px;
                border-bottom: 1px solid rgb(196, 196, 196);
              }
            }
          }
          tbody {
            tr {
              td {
                text-align: left;
                padding: 13px 20px;
              }
              &:nth-child(2n + 1) {
                background-color: rgba(208, 213, 217, 0.2);
              }
            }
          }
        }
      }
      .class-teachers{
        display: flex;
        flex-wrap: wrap;
        .class-teacher{
          border: 1px solid #e5e7eb;
          padding: 30px;
          border-radius: 5px;
          .teacher-details{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 20px;
            img{
              height: 100px;
            }
          }
        }
      }
    }
  }
  .add-position-form {
    padding: 20px;
    .loading-btn {
      display: flex;
      gap: 10px;
      align-items: center;
      width: max-content;
      padding: 10px 20px;
      background: #d8d8d8;
      border: none;
      color: white;
      font-size: 20px;
      &:hover {
        cursor: default;
      }
    }
    .submitButton {
      width: max-content;
      display: inline-flex;
      gap: 5px;

      align-items: center;

      vertical-align: top;
      cursor: pointer;

      background-color: #5b3016;
      padding: 12px 15px;
      color: rgb(255, 255, 255);
      border-radius: 3px;
      font-size: 12px;
      transition: all 0.3s ease-out 0s;
      border: none;
      margin-top: 10px;
      &:hover {
        background-color: #5b3016;
      }
    }
    .formInput {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 5px;
      .radioInline {
        display: flex;
        gap: 5px;
      }
      label {
        font-weight: 600;
        // color: #1fa8a1;
        margin-top: 15px;
      }
      input[type="time"],
      input[type="text"],
      input[type="number"],
      input[type="date"],
      input[type="password"],
      input[type="email"],
      select {
        padding: 10px;
        border: 1px solid rgb(137, 137, 137);
        border-radius: 3px;
        transition: 0.2s ease;
        outline: none;
        &:focus {
          outline: 1px solid blue;
        }
        &:invalid[focused="true"] ~ .error-message {
          display: block;
        }
        &:valid[focused="true"] {
          outline: 1px solid blue;
        }
        &:invalid[focused="true"] {
          outline: 1px solid red;
        }
      }
      small {
        color: rgb(179, 179, 179);
        font-size: 11px;
      }
      .error-message {
        font-size: 13px;
        color: red;
        display: none;
      }
      .formRow {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 10px;
        div {
          flex: 1;
        }
      }
    }
  }
  .transaction-history {
    padding: 15px;
    .payment-status {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px;
      border: 1px solid #cbcbcb;
      border-radius: 10px;
      margin-bottom: 10px;
      .left {
        img {
          height: 50px;
        }
      }
    }
    .transactions-list {
      .transaction {
        border: solid 1px #cbcbcb;
        border-radius: 5px;
        padding: 10px 20px;
        margin-bottom: 10px;
        .details {
          margin-top: 10px;
          display: flex;
          gap: 30px;
          justify-content: space-between;
          .detail {
            .detail-type {
              color: #0083ae;
            }
          }
          a {
            color: #0083ae;
            text-decoration: underline;
          }
        }
      }
    }
    .main-receipt {
      display: none;
      .receipt {
        border: 1px solid rgb(204, 204, 204);
        display: flex;
        flex-direction: column;
        padding: 20px 55px 50px;
        .header {
          display: flex;
          justify-content: space-between;
          gap: 30px;
          img {
            height: 70px;
            border-radius: 50%;
          }
          .school-details {
            text-align: center;
            h4 {
              font-size: 20px;
            }
          }
        }
        .body {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
// RESPONSIVENESS
@media screen and (max-width: 426px) {
  .positions-container {
    border: 1px solid rgb(230, 230, 230);
    height: max-content;
    border-radius: 10px;
    .tabs {
      display: flex;
      background-color: rgb(248, 248, 248);
      .tab {
        padding: 15px;
        font-size: 14px;
        &:hover {
          color: #3695dc;
        }
      }
      .active-tab {
        padding: 15px;
        font-size: 14px;
        background-color: white;
        border-bottom: 1px solid #3695dc;
        cursor: default !important;
        color: #3695dc;
      }
    }
    .content {
      display: none !important;
    }
    .show-content {
      .schedule-body {
        display: flex;
        flex-direction: column;
        .tab-cards {
          margin: 30px auto;
          width: 70%;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          
          .tab-card {
            flex: 1;
            -webkit-box-align: center;
            align-items: center;
            border-radius: 5px;
            color: rgb(0, 0, 0);
            background: rgb(255, 255, 255);
            
            transition: .2s ease-out;
            padding: 20px;
            &:nth-child(1){
              border: 1px solid #213363;
              .icon{
                background-color: #213363;
              }
            }
            &:nth-child(2){
              border: 1px solid #85A389;
              .icon{
                background-color: #85A389;
              }
            }
            &:nth-child(3){
              border: 1px solid #F29727;
              .icon{
                background-color: #F29727;
              }
            }
            &:hover{
              cursor: pointer;
              box-shadow: rgba(40, 40, 40, 0.2) 1px 3px 6px;
            }
            .icon {
              
              color: white;
              padding: 5px;
              border-radius: 50%;
            }
            h3{
              font-size: 22px;
              display: block;
              font-weight: bold;
            }
            p{
              margin-top: 5px;
              color: rgb(114, 114, 114);
              font-size: 12px;
            }
          }
        }
        .tab-top {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          .tab-top-left {
            flex: 2;
          }
          .tab-top-right {
            display: flex;
            align-items: center;
            flex: 1;
            gap: 20px;
            .print-button {
              display: inline-flex;
              overflow: hidden;
              -webkit-box-align: center;
              align-items: center;
              position: relative;
              vertical-align: top;
              cursor: pointer;
              height: 37px;
              background-color: rgb(0, 114, 187);
              padding: 0px 15px;
              color: rgb(255, 255, 255);
              border-radius: 3px;
              font-size: 12px;
              transition: all 0.3s ease-out 0s;
              &:hover {
                background-color: rgba(0, 114, 187, 0.9);
              }
            }
            .search-box {
              input {
                width: 100%;
                height: 42px;
                line-height: 150%;
                padding-left: 10px;
                padding-right: 10px;
                background-color: rgb(255, 255, 255);
                border: 1px solid rgb(216, 216, 227);
                border-radius: 3px;
                outline: none;
                transition: all 0.3s ease-out 0s;
                box-sizing: border-box;
                color: rgb(56, 58, 63);
                &:focus {
                  border: 1px solid rgb(59, 130, 246);
                }
              }
            }
          }
        }
        .tab-bottom {
          display: block;
          margin: 20px 0px;
          font-size: 15px;
          background-color: rgb(255, 255, 255);
          border: 1px solid rgb(208, 213, 217);
          border-radius: 5px;
          table {
            background-color: rgb(255, 255, 255);
            border-collapse: collapse;
            border-radius: 5px;
            width: 100% !important;
            thead {
              tr {
                th {
                  text-align: left;
                  font-weight: bold;
                  padding: 20px;
                  border-bottom: 1px solid rgb(196, 196, 196);
                }
              }
            }
            tbody {
              tr {
                td {
                  text-align: left;
                  padding: 13px 20px;
                }
                &:nth-child(2n + 1) {
                  background-color: rgba(208, 213, 217, 0.2);
                }
              }
            }
          }
        }
        .class-teachers{
          display: flex;
          flex-wrap: wrap;
          .class-teacher{
            border: 1px solid #e5e7eb;
            padding: 30px;
            border-radius: 5px;
            .teacher-details{
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              gap: 20px;
              img{
                height: 100px;
              }
            }
          }
        }
      }
    }
    .add-position-form {
      padding: 20px;
      .loading-btn {
        display: flex;
        gap: 10px;
        align-items: center;
        width: max-content;
        padding: 10px 20px;
        background: #d8d8d8;
        border: none;
        color: white;
        font-size: 20px;
        &:hover {
          cursor: default;
        }
      }
      .submitButton {
        width: max-content;
        display: inline-flex;
        gap: 5px;

        align-items: center;
  
        vertical-align: top;
        cursor: pointer;
  
        background-color: #5b3016;
        padding: 12px 15px;
        color: rgb(255, 255, 255);
        border-radius: 3px;
        font-size: 12px;
        transition: all 0.3s ease-out 0s;
        border: none;
        margin-top: 10px;
        &:hover {
          background-color: #5b3016;
        }
      }
      .formInput {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 5px;
        .radioInline {
          display: flex;
          gap: 5px;
        }
        label {
          font-weight: 600;
          // color: #1fa8a1;
          margin-top: 15px;
        }
        input[type="time"],
        input[type="text"],
        input[type="number"],
        input[type="date"],
        input[type="password"],
        input[type="email"],
        select {
          padding: 10px;
          border: 1px solid rgb(137, 137, 137);
          border-radius: 3px;
          transition: 0.2s ease;
          outline: none;
          &:focus {
            outline: 1px solid blue;
          }
          &:invalid[focused="true"] ~ .error-message {
            display: block;
          }
          &:valid[focused="true"] {
            outline: 1px solid blue;
          }
          &:invalid[focused="true"] {
            outline: 1px solid red;
          }
        }
        small {
          color: rgb(179, 179, 179);
          font-size: 11px;
        }
        .error-message {
          font-size: 13px;
          color: red;
          display: none;
        }
        .formRow {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-wrap: wrap;
          gap: 10px;
          div {
            flex: 1;
          }
        }
      }
    }
    .transaction-history {
      padding: 15px;
      .payment-status {
        display: flex;
        justify-content: space-between;
        padding: 20px 10px;
        border: 1px solid #cbcbcb;
        border-radius: 10px;
        margin-bottom: 10px;
        .left {
          img {
            height: 50px;
          }
        }
      }
      .transactions-list {
        .transaction {
          overflow: scroll;
          .details {
            
            gap: 20px;
            font-size: 10px;
            .detail {
              flex: 1;
              .detail-type {
                color: #0083ae;
              }
            }
            a {
              color: #0083ae;
              text-decoration: underline;
            }
          }
        }
      }
      .main-receipt {
        display: none;
        .receipt {
          border: 1px solid rgb(204, 204, 204);
          display: flex;
          flex-direction: column;
          padding: 20px 55px 50px;
          .header {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            img {
              height: 70px;
              border-radius: 50%;
            }
            .school-details {
              text-align: center;
              h4 {
                font-size: 20px;
              }
            }
          }
          .body {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}