.export-button{
  margin: 0px 10px 20px 0px;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  border-radius: 3px;
  font-size: 12px;
  transition: all 0.3s ease-out 0s;
  border: 1px solid rgb(0, 114, 187);
  color: rgb(0, 114, 187);
  z-index: 0;
  &:hover{
    background-color: rgb(0, 114, 187);
    cursor: pointer;
    color: white;
  }
}
.class-body {
  display: flex;
  gap: 20px;
  .left {
    flex: 1;
    padding: 10px 20px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 5px;
    p {
      margin-bottom: 20px;
      border-bottom: 1px solid gray;
      padding: 5px 0;
      font-weight: 500;
      font-size: 16px;
      color: rgb(67, 89, 255);
    }
    form {
      .classFormGroup {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        label {
          font-weight: 500;
        }
        input,
        select,
        textarea {
          padding: 10px;
          border: 1px solid rgb(205, 205, 205);
          border-radius: 5px;
          transition: 0.2s ease;
          outline: none;
          &:focus {
            outline: 1px solid blue;
          }
        }

        .addMoreBtn {
          margin-top: 20px;
          padding: 10px 20px;
          border: none;
          color: white;
          text-align: center;
          background-color: rgb(92, 92, 255);
          cursor: pointer;
        }
      }
      .submitButton {
        display: inline-flex;
        overflow: hidden;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        vertical-align: top;
        cursor: pointer;
        height: 38px;
        padding: 0px 15px;
        color: rgb(255, 255, 255);
        border-radius: 3px;
        font-size: 12px;
        transition: all 0.3s ease-out 0s;

        background: #5b3016;
        border: none;
        &:hover {
          cursor: pointer;
          background: #5b3016;
        }
      }
    }
  }
  .right {
    flex: 2;
    width: 50%;
  }
}

.schedule-body {
  .no-data-msg {
    text-align: center;
    margin-top: 100px;
    font-size: 40px;
    font-weight: bold;
    width: 100%;
  }
  .filter-columns {
    display: flex;
    justify-content: center;
    align-items: center;
    form {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 40px;
      width: 100%;
      .formInput {
        display: flex;
        gap: 10px;
        width: 22%;
        flex-direction: column;
        label {
          font-weight: 500;
        }
        select,
        input {
          padding: 10px;
          border: 1px solid rgb(205, 205, 205);
          border-radius: 2px;
          transition: 0.2s ease;
          outline: none;
          width: 100%;
        }
      }
      button,
      .add-schedule {
        display: inline-flex;
        overflow: hidden;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        vertical-align: top;
        cursor: pointer;
        height: 38px;
        padding: 0px 15px;
        color: rgb(255, 255, 255);
        border-radius: 3px;
        font-size: 12px;
        transition: all 0.3s ease-out 0s;

        background: #5b3016;
        border: none;
        &:hover {
          cursor: pointer;
          background: #5b3016;
        }
      }
      .add-btn {
        border: 1px solid #1fa8a1;
        padding: 10px 20px;
        transition: 0.5s ease;
        &:hover {
          background-color: #1fa8a1;
          color: white;
          cursor: pointer;
        }
      }
    }
    button {
      padding: 10px 20px;
      background: #1fa8a1;
      border: none;
      color: white;
      font-size: 15px;
      transition: 0.4s ease;
      &:hover {
        cursor: pointer;
        background: #1c9c96;
      }
    }
  }
  .schedule {
    // margin-top: 40px;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: center;
    margin: 50px 0px;
    background-color: rgb(255, 255, 255);
    
    border-radius: 5px;
    // FOR CLASS SCHEDULES
    .schedule-table {
      width: 100%;
      margin: auto;
      display: flex;
      .day-schedule {
        width: 100%;
        .day {
          text-transform: uppercase;
          background-color: #0A6EBD;
          color: white;
          border: .2px solid #9DB2BF;
          padding: 15px 5px;
          text-align: center;
        }
        .schedule-details {
          border: 1px solid #9DB2BF;
          padding: 15px 5px;
          text-align: center;
          display: flex;
          height:90px;
          overflow: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          .left{
            flex: 1;
          }
          .right{
            color: #EF6262;
            cursor: pointer;
          }
        }
      }
    }
    table {
      background-color: rgb(255, 255, 255);
      border-collapse: collapse;
      border: 1px solid rgb(208, 213, 217);
      width: 100% !important;
      thead{
        color: rgb(56, 58, 63);
        border-bottom: none;
        th {
          text-transform: uppercase;
          text-align: left;
          font-weight: bold;
          padding: 13px 20px;
          border: 0 solid #e5e7eb;
        }
      }
      tbody{
        tr{
          &:nth-child(2n+1){
            background-color: rgba(208, 213, 217, 0.1);
          }
          td{
            padding: 15px 20px;
            text-align: left;
          }
        }
      }
    }
    .attendance-report-table{
      border-collapse: collapse;
      background: rgb(255, 255, 255);
      border-top: 1px solid rgb(216, 216, 227);
      width: 100%;
      height: 100%;
      overflow: scroll;
      position: relative;
      table-layout: auto;
      white-space: nowrap;
      transition: all 0.3s ease-in-out 0s;
      font-size: 12px;
      thead{
        background: rgb(245, 245, 247);
        transition: all 0.3s ease-in-out 0s;
        th{
          padding: 10px 5px;
          font-weight: 400;
          border-right: 1px solid rgb(216, 216, 227);
          border-bottom: 1px solid rgb(216, 216, 227);
          transition: all 0.3s ease-out 0s;
          position: relative;
          z-index: 0;
          text-align: center;
        }
      }
      tbody{
        tr{
          td{
            border-bottom: 1px solid rgb(216, 216, 227);
            border-right: 1px solid rgb(216, 216, 227);
            padding: 0px;
            width: 70px;
            transition: all 0.3s ease-out 0s;
            position: relative;
            z-index: 0;
          }
        }
      }
    }
   
  }
  .no-schedule {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 60vh;
    font-size: 50px;
  }
  .student-list {
    border-radius: 5px;
    border: 1px solid #d1d1d1;
    background-color: #fdfdfd;
    margin-top: 50px;
    .panel-header {
      background-color: #f6f6f6;
      background: #f6f6f6;
      border-radius: 5px 5px 0 0;
      position: relative;
      border-bottom: 2px solid #045a8d;
      padding: 10px 15px;
      h4 {
        color: #33353f;
        font-weight: 400;
        line-height: 20px;
        padding: 0;
        text-transform: none;
        font-size: 17px;
      }
    }
    .list-body {
      border-radius: 0 0 5px 5px;
      background-color: #fdfdfd;
      --webkit-box-shadow: none;
      margin-bottom: 10px !important;
      padding: 15px;
      table {
        text-align: left;
        width: 100%;
        border: 1px solid #ddd;
        max-width: 100%;
        background-color: transparent;
        border-spacing: 0;
        border-collapse: collapse;
        th,
        td {
          padding: 10px;
        }
        thead {
          background-color: #ececec;
        }
        tbody {
          tr {
            border-top: 1px solid rgb(206, 206, 206);
          }
          input[type="number"] {
            padding: 10px;
            border: 1px solid rgb(205, 205, 205);
            border-radius: 5px;
            transition: 0.2s ease;
            outline: none;
          }
        }
      }
      .graduating-badge-form{
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        select,
          input {
            padding: 10px;
            border: 1px solid rgb(205, 205, 205);
            border-radius: 2px;
            transition: 0.2s ease;
            outline: none;
            width: 30%;
          }
      }
      .save-button {
        width: max-content;
        display: inline-flex;
        gap: 5px;
        overflow: hidden;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        vertical-align: top;
        cursor: pointer;
        height: 38px;
        background-color: #5b3016;
        padding: 0px 15px;
        color: rgb(255, 255, 255);
        border-radius: 3px;
        font-size: 12px;
        transition: all 0.3s ease-out 0s;
        border: none;
        &:hover{
            background-color:#5b3016;
        }
      }
    }
  }
  .student-fee-body {
    padding: 20px;
    border: 1px solid rgb(199, 199, 199);
    width: 70%;
    margin: 30px auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .school-info {
      display: flex;
      justify-content: space-between;
      .logo {
        img {
          height: 100px;
          border-radius: 50%;
        }
      }
    }
    .student-term-info {
      display: flex;
      justify-content: space-between;
    }
    .bill {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      table {
        border: 1px solid #eee;
        border-collapse: collapse;
        width: 70%;
        thead {
          th {
            color: white;
          }
        }
        td,
        th {
          border: 1px solid #ddd;
          text-align: left;
          padding: 8px;
        }
        tfoot {
          th {
            
          }
        }
      }
    }
    .result {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      table {
        width: 100%;
        border: 1px solid gray;
        border-collapse: collapse;
        tr {
          th {
            font-weight: 600;
            font-size: 15px;
          }
          th,
          td {
            border: 1px solid #ddd;
            padding: 8px;
            line-height: 1.428;
            text-align: left;
          }
        }
      }
    }
    .info-text {
      text-align: center;
      font-size: 15px;
      color: #8a8a8a;
      margin-top: 50px;
    }
  }
  .eyfs-student-report{
    padding: 20px 55px 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    overflow: hidden;
    .school-info{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .school-logo{
        img{
          height: 150px;
          border-radius: 50%;
        }
      }
      .school-details{
        display:flex;
        align-items: center;
        flex-direction: column;
        h3{
          font-size: 40px;
        }
      }
      .academic-info{
        display: flex;
        justify-content: space-around;
      }
    }
  }
  .income-statement{
    
    width: 80%;
    margin:30px auto;
    .export-button{
      margin: 0px 10px 20px 0px;
      display: inline-flex;
      align-items: center;
      background-color: transparent;
      padding: 10px;
      border-radius: 3px;
      font-size: 12px;
      transition: all 0.3s ease-out 0s;
      border: 1px solid rgb(0, 114, 187);
      color: rgb(0, 114, 187);
      z-index: 0;
      &:hover{
        background-color: rgb(0, 114, 187);
        cursor: pointer;
        color: white;
      }
    }
    .add-button{
      margin: 0px 10px 20px 0px;
      display: inline-flex;
      align-items: center;
      background-color: transparent;
      padding: 10px;
      border-radius: 3px;
      font-size: 12px;
      transition: all 0.3s ease-out 0s;
      border: 1px solid rgb(0, 114, 187);
      background-color: rgb(0, 114, 187);
      color: white;
      z-index: 0;
      &:hover{
        color: rgb(0, 114, 187);
        cursor: pointer;
        background-color: white;
      }
    }
    table{
      border-collapse: collapse;
      width: 100%;
      
      td,th{
        border: 1px solid #ddd;
        padding: 8px;
      }
      .amount-cell{
        text-align: right;
      }
      tr{
        &:nth-child(even){
          background-color: #f2f2f2;
        }
      }
      tbody{
        .list-item-details{
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
  }
}

// RESPONSIVENESS
@media screen and (max-width: 426px) {
  .schedule-body {
    .no-data-msg {
      margin-top: 0px;
      font-size: 30px;
    }
    .filter-columns {
      display: flex;
      justify-content: center;
      align-items: center;
      form {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        .formInput {
          display: flex;
          gap: 10px;
          width: -webkit-fill-available;
          flex-direction: column;
          label {
            font-weight: 500;
          }
          select,
          input {
            padding: 10px;
            border: 1px solid rgb(205, 205, 205);
            border-radius: 2px;
            transition: 0.2s ease;
            outline: none;
            width: 100%;
          }
        }
        button,
        .add-schedule {
          display: inline-flex;
          overflow: hidden;
          -webkit-box-align: center;
          align-items: center;
          position: relative;
          vertical-align: top;
          cursor: pointer;
          height: 38px;
          padding: 0px 15px;
          color: rgb(255, 255, 255);
          border-radius: 3px;
          font-size: 12px;
          transition: all 0.3s ease-out 0s;
  
          background: #5b3016;
          border: none;
          &:hover {
            cursor: pointer;
            background: #5b3016;
          }
        }
        .add-btn {
          border: 1px solid #1fa8a1;
          padding: 10px 20px;
          transition: 0.5s ease;
          &:hover {
            background-color: #1fa8a1;
            color: white;
            cursor: pointer;
          }
        }
      }
      button {
        padding: 10px 20px;
        background: #1fa8a1;
        border: none;
        color: white;
        font-size: 15px;
        transition: 0.4s ease;
        &:hover {
          cursor: pointer;
          background: #1c9c96;
        }
      }
    }
    .schedule {
      overflow: scroll;
      // FOR CLASS SCHEDULES
      .schedule-table {
        width: 100%;
        margin: auto;
        display: flex;
        .day-schedule {
          width: 100%;
          .day {
            text-transform: uppercase;
            background-color: #0A6EBD;
            color: white;
            border: .2px solid #9DB2BF;
            padding: 15px 5px;
            text-align: center;
          }
          .schedule-details {
            border: 1px solid #9DB2BF;
            padding: 15px 5px;
            text-align: center;
            display: flex;
            height:90px;
            overflow: scroll;
            &::-webkit-scrollbar {
              display: none;
            }
            .left{
              flex: 1;
            }
            .right{
              color: #EF6262;
              cursor: pointer;
            }
          }
        }
      }
      table {

        width: max-content !important;
        thead{
          color: rgb(56, 58, 63);
          border-bottom: none;
          th {
            text-transform: uppercase;
            text-align: left;
            font-weight: bold;
            padding: 13px 20px;
            border: 0 solid #e5e7eb;
          }
        }
        tbody{
          tr{
            &:nth-child(2n+1){
              background-color: rgba(208, 213, 217, 0.1);
            }
            td{
              padding: 15px 20px;
              text-align: left;
            }
          }
        }
      }
      .attendance-report-table{
        border-collapse: collapse;
        background: rgb(255, 255, 255);
        border-top: 1px solid rgb(216, 216, 227);
        width: 100%;
        height: 100%;
        overflow: scroll;
        position: relative;
        table-layout: auto;
        white-space: nowrap;
        transition: all 0.3s ease-in-out 0s;
        font-size: 12px;
        thead{
          background: rgb(245, 245, 247);
          transition: all 0.3s ease-in-out 0s;
          th{
            padding: 10px 5px;
            font-weight: 400;
            border-right: 1px solid rgb(216, 216, 227);
            border-bottom: 1px solid rgb(216, 216, 227);
            transition: all 0.3s ease-out 0s;
            position: relative;
            z-index: 0;
            text-align: center;
          }
        }
        tbody{
          tr{
            td{
              border-bottom: 1px solid rgb(216, 216, 227);
              border-right: 1px solid rgb(216, 216, 227);
              padding: 0px;
              width: 70px;
              transition: all 0.3s ease-out 0s;
              position: relative;
              z-index: 0;
            }
          }
        }
      }
     
    }
    .no-schedule {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 60vh;
      font-size: 50px;
    }
    .student-list {
      border-radius: 5px;
      border: 1px solid #d1d1d1;
      background-color: #fdfdfd;
      margin-top: 50px;
      .panel-header {
        background-color: #f6f6f6;
        background: #f6f6f6;
        border-radius: 5px 5px 0 0;
        position: relative;
        border-bottom: 2px solid #045a8d;
        padding: 10px 15px;
        h4 {
          color: #33353f;
          font-weight: 400;
          line-height: 20px;
          padding: 0;
          text-transform: none;
          font-size: 17px;
        }
      }
      .list-body {
        overflow: scroll;
        table {
          text-align: left;
          width: 100%;
          border: 1px solid #ddd;
          max-width: 100%;
          background-color: transparent;
          border-spacing: 0;
          border-collapse: collapse;
          th,
          td {
            padding: 10px;
          }
          thead {
            background-color: #ececec;
          }
          tbody {
            tr {
              border-top: 1px solid rgb(206, 206, 206);
            }
            input[type="number"] {
              padding: 10px;
              border: 1px solid rgb(205, 205, 205);
              border-radius: 5px;
              transition: 0.2s ease;
              outline: none;
            }
          }
        }
        .save-button {
          width: max-content;
          display: inline-flex;
          gap: 5px;
          overflow: hidden;
          -webkit-box-align: center;
          align-items: center;
          position: relative;
          vertical-align: top;
          cursor: pointer;
          height: 38px;
          background-color: #5b3016;
          padding: 0px 15px;
          color: rgb(255, 255, 255);
          border-radius: 3px;
          font-size: 12px;
          transition: all 0.3s ease-out 0s;
          border: none;
          margin-top: 10px;
          &:hover{
              background-color:#5b3016;
          }
        }
      }
    }
    .student-fee-body {
      padding: 20px;
      border: 1px solid rgb(199, 199, 199);
      width: 60%;
      margin: 30px auto;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .school-info {
        display: flex;
        justify-content: space-between;
        .logo {
          img {
            height: 100px;
            border-radius: 50%;
          }
        }
      }
      .student-term-info {
        display: flex;
        justify-content: space-between;
      }
      .bill {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
  
        table {
          border: 1px solid #eee;
          border-collapse: collapse;
          width: 70%;
          thead {
            th {
              color: white;
            }
          }
          td,
          th {
            border: 1px solid #ddd;
            text-align: left;
            padding: 8px;
          }
          tfoot {
            th {
              
            }
          }
        }
      }
      .result {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        table {
          width: 100%;
          border: 1px solid gray;
          border-collapse: collapse;
          tr {
            th {
              font-weight: 600;
              font-size: 15px;
            }
            th,
            td {
              border: 1px solid #ddd;
              padding: 8px;
              line-height: 1.428;
              text-align: left;
            }
          }
        }
      }
      .info-text {
        text-align: center;
        font-size: 15px;
        color: #8a8a8a;
        margin-top: 50px;
      }
    }
    .eyfs-student-report{
      padding: 20px 0px 50px;
      .school-info{
        
        .school-details{
        
          p{
            text-align: center;
          }
        }
        
      }
    }
    .income-statement{
      
      width: 100%;
      margin:30px auto;
      .export-button{
        margin: 0px 10px 20px 0px;
        display: inline-flex;
        align-items: center;
        background-color: transparent;
        padding: 10px;
        border-radius: 3px;
        font-size: 12px;
        transition: all 0.3s ease-out 0s;
        border: 1px solid rgb(0, 114, 187);
        color: rgb(0, 114, 187);
        z-index: 0;
        &:hover{
          background-color: rgb(0, 114, 187);
          cursor: pointer;
          color: white;
        }
      }
      .add-button{
        margin: 0px 10px 20px 0px;
        display: inline-flex;
        align-items: center;
        background-color: transparent;
        padding: 10px;
        border-radius: 3px;
        font-size: 12px;
        transition: all 0.3s ease-out 0s;
        border: 1px solid rgb(0, 114, 187);
        background-color: rgb(0, 114, 187);
        color: white;
        z-index: 0;
        &:hover{
          color: rgb(0, 114, 187);
          cursor: pointer;
          background-color: white;
        }
      }
      table{
        border-collapse: collapse;
        width: max-content !important;
        
        td,th{
          border: 1px solid #ddd;
          padding: 8px;
        }
        .amount-cell{
          text-align: right;
        }
        tr{
          &:nth-child(even){
            background-color: #f2f2f2;
          }
        }
      }
      .remarks{
        .detail{
          h3{
            font-size: 13px;
          }
          p{
            font-size: 12px;
          }
        }
      }
      .signatures{
        h3{
          font-size: 13px;
        }
        p{
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .class-body {
    flex-direction: column;
    .right {
      width: auto;
    }
  }
}
