.event-detail-container{
    display: flex;
    gap: 20px;
    height: 100vh;
    
    .event-left{
        flex: 2;
    }
    .event-right{
        padding: 15px;
        flex: 1;
        border-left: 1px solid rgb(231, 231, 231);
        h4{
            font-size: 20px;
            text-transform: uppercase;
        }
        .event{
            margin-top: 20px;
            background-color: #EAEDFA;
            padding: 10px 15px;
            h3{
                color:#303030;
            }
            .description{
                color:#A8A8AA
            }
            .date-time{
                margin-top: 5px;
                font-size: 12px;
                color: #068ccf;
                font-weight: 500;
            }
        }
    }
}