#features{
    padding: 50px 100px 100px;
    // height: 100vh;
    height: max-content;
    background-color: #ECF0F1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    overflow: hidden;
    
    .features-left{
        
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .header{
            display: flex;
            flex-direction: column;
            gap: 50px;
            h4{
                font-weight: 400;
            }
            p{
                font-size: 14px;
                font-style: italic;
                line-height: 1.4;
            }
        }
        .main{
            display: flex;
            flex-direction: column;
            gap: 30px;
            p{
                font-size: 13px;
                line-height: 1.3;
                text-align: justify;
                word-spacing: 3px;
            }
            .list{
                display: flex;
                gap: 50px;
                li{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;
                    padding:3px
                }
            }
        }
        .features-button{
            margin-top:15px;
            background-color: #E74C3C;
            width: max-content;
            color: white;
            padding: 10px 20px;
            transition: .5s ease;
            &:hover{
                background-color: #b51d0c;
                cursor: pointer;
            }
        }
    }
    .features-right{
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .features-image{
            width: 103%;
        }
    }
}


// MEDIA QUERIES
@media screen and (max-width: 1024px){
    #features{
        height: max-content;
        padding: 40px 40px 60px;
        .features-left{
            flex: 1 1;
            .header{
                
                gap: 20px;
                
                p{
                    font-size: 12px;
                }
            }
            .main{
                
                p{
                    font-size: 12px;
                
                }
                .list{
                
                    justify-content: space-around;
                
                    li{
                
                        gap: 5px;
                        padding:3px;
                        font-size: 11px;
                    }
                }
            }
           
        }
        .features-right{
            flex: 1 1;
            align-items: flex-start;
            .features-image{
                height: 40%;
            }
        }
    }
}

@media screen and (max-width: 768px){
    #features{

        height: max-content;
        
        .features-left{
        
            flex: 1 0 266px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .header{
                display: flex;
                flex-direction: column;
                gap: 50px;
                h4{
                    font-weight: 400;
                }
                p{
                    font-size: 13px;
                    font-style: italic;
                    line-height: 1.4;
                }
            }
            .main{
                display: flex;
                flex-direction: column;
                gap: 30px;
                p{
                    font-size: 14px;
                    line-height: 1.3;
                    text-align: justify;
                    word-spacing: 3px;
                }
                .list{
                    display: flex;
                    justify-content: space-around;
                    gap: 50px;
                    li{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 5px;
                        padding:3px;
                        font-size: 13px;
                    }
                }
            }
           
        }
        .features-right{
            display: none;
        }
    }
}


@media screen and (max-width: 426px){
    #features{
        height: max-content;
        padding: 30px 30px 50px;
    }
}

