#topbar{
    padding: 9px 30px;
    background-color: #ffffff;
    position: fixed;
    width: -webkit-fill-available;
    display: flex;
    top: 0;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    box-shadow: 0 5px 10px -2px rgba(0,0,0,.1);
    min-height: 50px;
    .school-details{
        flex: 1;
        display: flex;
        align-items: center;
        gap: 20px;
        .bars{
            font-size: 25px;
            &:hover{
                cursor: pointer;
            }
        }
        .school-logo{
            img{
                border-radius: 100%;
                height: 50px;
            }
        }
        .school-name-position{
            .school-name{
                font-size: 25px;
                font-weight: 700;
            }
        }
    }
    
    .user-section{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        .user-image{
            img{
                height: 30px;
                width: 30px;
                border-radius: 100%;
            }
        }
        .details{
            .username{
                font-size: 12px;
                color: rgb(56, 58, 63);
            }
            .email{
                font-size: 10.24px;
                color: rgb(157, 157, 183);
            }
        }
        .dropdown-arrow{
            cursor: pointer;
        }
        .dropdown{
            position: absolute;
            top: 3.0rem;
            right: 2rem;
            border-radius: 3px;
            border: 1px solid rgb(219, 219, 219);
            margin: 10px 0;
            -webkit-box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.12);
            -moz-box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.12);
            box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.12);
            background-color: white;
            min-width: 150px;
            width: max-content;
            &::before{
                content: "";
                position: absolute;
                top: -0.4rem;
                right: .5rem;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                background-color: white;
                border-left: 1px solid  rgb(219, 219, 219);
                border-top: 1px solid  rgb(219, 219, 219);
            }
            ul{
                li{
                    padding: 10px;
                    font-size: 13px;
                    color: #2e2e2e;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    &:hover{
                        background-color: #EEF8FF;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}


// RESPONSIVENESS
@media screen and (max-width: 426px) {
    #topbar{
        .icons{
            display: none;
        }
        .search-bar{
            display: none;
        }
        .school-details{
            gap: 10px;
            .school-logo{
                margin-left: 5px;
                img{
                    height: 31px;
                }
            }
            .school-name-position{
                .school-name{
                    font-size: 13px;
                }
                .position{
                    font-size: 13px;
                }
            }
        }
        .user-section{
            gap: 3px;
            .user-image{
                display: none;
            }
            .details{
                .username{
                    font-size: 12px;
                }
                .email{
                    display: none;
                }
            }
            .dropdown-arrow{
                margin-top: 4px;
            }
        }
    }
}

// RESPONSIVENESS
@media screen and (max-width: 769px) {
    #topbar{
        .icons{
            display: none;
        }
        .search-bar{
            display: none;
        }
    }
}